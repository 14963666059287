.searching
  position: relative
  a
    color: inherit

  &__results
    width: 100%
    padding: 20px
    margin-top: 10px
    min-height: 300px
    position: absolute
    background-color: #fff
    border-radius: 13px
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)

.children
  display: block
  cursor: pointer
  font-weight: 400
  font-size: 15px
  line-height: 17px
  p
    transition: color .2s ease-in-out
    &:hover
      color: #546EDB
  &:not(:last-child)
    margin-bottom: 10px

  svg
    transform: rotateZ(180deg)

.subcategory
  div
    display: flex
    gap: 15px
    align-items: center

.parent, .subcategory
  display: flex
  gap: 15px
  margin-bottom: 10px
  align-items: center
  font-weight: 700
  font-size: 15px
  line-height: 18px
  cursor: pointer
  &__title
    transition: color .2s ease-in-out
  &:hover &__title
    color: #546EDB
  span
    background: #546EDB
    color: #fff
    width: 40px
    height: 25px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 100%

@media(max-width: 768px)
  .searching
    position: absolute
    top: 0
    left: 8px
    bottom: 0
  .searching
    width: 70%
