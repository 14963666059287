.partners
    &__top_wrapper
        display: flex
        justify-content: space-between
        margin-bottom: 20px
        &_right
            display: flex
            gap: 10px
        .new_section
            display: flex
            align-items: center
            padding: 10px
            gap: 5px
            background: #fff
            border: 1px solid #546EDB
            cursor: pointer
            font-weight: 700
            font-size: 14px
            line-height: 20px
            color: #546EDB
            border-radius: 10px
            transition: color 0.3s ease-in-out, background 0.3s ease-in-out
            &:hover
                color: #fff
                background: #546EDB
                svg path
                    stroke: #fff
        &_left
            h3
                margin-bottom: 0
    .section
        margin-bottom: 20px
        &_titles
            display: grid
            grid-template-columns: 20% 70% 10%
            margin-bottom: 20px
            font-weight: 800
            font-size: 16px
            line-height: 20px
            color: #C4C4C4
        &_title
            display: flex
            gap: 5px
            margin-bottom: 20px
            font-weight: 700
            font-size: 18px
            line-height: 21px
            div
                cursor: pointer
                border-radius: 100%
                width: 20px
                height: 20px
                display: flex
                align-items: center
                justify-content: center
                padding: 3px
                &:hover
                    background: #546EDB
                    path
                        fill: #fff

        &_content
            display: grid
            grid-template-columns: 20% 70% 10%
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
            border-radius: 14px
            align-items: center
            padding: 10px
            a
                &:hover path
                    fill: #546EDB

            img
                border-radius: 10px
                width: 53px
                height: 53px
                object-fit: cover
.create
    &__top_wrapper
        display: flex
        justify-content: space-between
        margin-bottom: 20px
        h3
            margin-bottom: 0
        .right
            display: flex
            gap: 10px
            position: relative
            div
                position: absolute
                bottom: -20px
                span
                    font-size: 12px
        .left
            display: flex
            gap: 5px
            align-items: center
            div
                cursor: pointer
        .new_section
            padding: 7px
            display: flex
            gap: 5px
            align-items: center
            border-radius: 10px
            font-weight: 500
            border: 1px solid #546EDB
            cursor: pointer
            color: #546EDB
            transition: color 0.3s ease-in-out, background 0.3s ease-in-out
    .selects
        margin-bottom: 30px
    .photo
        margin-bottom: 45px
    .input
        padding: 15px
        background: #F7F7FE
        border-radius: 14px
        border: 1px solid rgba(84, 110, 219, 0.2)
        width: 100%
        margin-bottom: 20px
        width: 100%

    .inputs_wrapper
        width: 50%
