.container
    width: 100%
    background: #ffffff
    border: 1px solid #dde2f8
    border-radius: 14px
    height: 100%
    position: relative
.objects_container
    display: flex
    flex-direction: column
.top
    padding: 25px
    display: flex
    gap: 10px
    justify-content: space-between
.steps
    display: flex
    justify-content: space-around
.step
    display: flex
    gap: 10px
    align-items: center
    p
        max-width: 170px
    &:nth-child(1)
        span::before
            background-image: url(../../assets/images/cabinet/horn.webp)
    &:nth-child(2)
        span::before
            background-image: url(../../assets/images/cabinet/rocket.webp)

    span
        padding-left: 50px
        font-weight: 800
        font-size: 40px
        line-height: 50px
        color: #6FE1B8
        position: relative

        &::before
            content: ""
            position: absolute
            width: 50px
            height: 50px
            border-radius: 100%
            background-color: #FFFFFF
            left: 10px
            border: 1px solid #6FE1B8
            background-repeat: no-repeat
            background-position: center

.link_wrapper
    position: relative
    z-index: 33

.link
    padding: 15px 25px
    background: #546EDB
    border-radius: 10px
    font-weight: 500
    font-size: 16px
    line-height: 19px
    position: relative
    color: #FFFFFF
    display: flex
    align-items: center
    justify-content: center
    font-family: "Gilroy", sans-serif
    cursor: pointer
    position: relative
    height: 100%
    &::before
        content: ""
        background-color: #6FE1B8
        background-image: url(../../assets/images/cabinet/plus.png)
        border-radius: 10px
        width: 100px
        height: 100%
        position: absolute
        left: -50px
        z-index: -1
        background-repeat: no-repeat
        background-position: center left 15px

.bottom
    padding: 40px
    border-top: 1px solid #DDE2F8
    border-radius: 8px 8px 14px 14px
    h3
        font-weight: 800
        font-size: 32px
        line-height: 100%
        text-align: center
        span
            font-size: 24px
    img
        margin: 0 auto
        display: block
        margin-bottom: 20px
    .text
        font-family: 'Roboto'
        max-width: 420px
        text-align: center
        margin: 0 auto
        margin-bottom: 20px
    &__button
        margin: 30px auto
        width: 220px

.types
    padding: 22px
    border-top: 1px solid #dde2f8
    border-radius: 8px

.objects_list
    padding: 22px
    border-top: 1px solid #dde2f8
    border-radius: 8px
    height: 100%
    display: flex
    flex-direction: column
    justify-content: space-between

.objects_wrapper
    height: 100%
    display: flex
    flex-direction: column

.object_choose_button
    margin-right: 0
    padding: 20px 60px
    background: #546EDB
    border-radius: 5px
    font-weight: 500
    font-size: 16px
    line-height: 19px
    color: #FFFFFF
    cursor: pointer

.object_choose_wrapper
    display: flex
    align-items: center

.pagination
    padding: 0 20px

@media screen and (max-width: 1200px)
    .top
        flex-direction: column
        gap: 20px
    .link
        max-width: 228px
        width: auto
        margin: 0 auto
    .bottom__button
        width: 100%
    .link::before
        left: unset
        right: -50px
        background-position: center right 15px

@media screen and (max-width: 760px)

    .object_choose_wrapper
        justify-content: center

@media screen and (max-width: 576px)

    .link::before
        display: none
    .steps
        justify-content: space-around
        flex-direction: column
        gap: 20px
