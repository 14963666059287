.wrapper
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 50
    background: rgba(0, 0, 0, 0.6)
.modal
    background: #fff
    box-shadow: 0 4px 4px rgba(0,0,0,.25)
    display: flex
    flex-direction: column
    justify-content: space-between
    left: 50%
    width: 400px
    border-radius: 10px
    padding: 20px
    position: relative
    top: 50%
    -webkit-transform: translate(-50%,-50%)
    transform: translate(-50%,-50%)
    z-index: 100
    .title
        margin-bottom: 15px
    input
        width: 100%
        margin-bottom: 20px
        padding: 0 10px
        height: 48px
        background: #f6f7fe
        border-radius: 10px
        font-family: "Gilroy", sans-serif
        font-weight: 500
        font-size: 14px
        line-height: 16px
        color: #414253
        border: 1px solid rgba(84, 110, 219, 0.2)
    button
        width: 100%
    p
        margin-bottom: 20px
.overlay_close
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
