.flex{
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.id{
    font-size: 14px;
    text-decoration: underline;
}

.desktop_layout {
    display: flex;
    gap: 20px;
    height: 100%;
}
.dialogs__list {
    max-width: 46%;
    border-right: 1px solid #c4c4c4;
    &_wrapper {
        padding-right: 10px;
        max-height: 600px;
        overflow: auto;
    }
    .dialog_wrapper {
        cursor: pointer;
        border-radius: 8px;
        padding: 10px 5px;
        transition: background 0.3s ease-in-out;
        &:hover {
            background: #f7f7fe;
        }
    }
    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        span {
            font-weight: 400;
            font-size: 10px;
            text-align: right;
            line-height: 16px;
            color: #858585;
        }
    }
    .name {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #414253;
        width: min-content;
    }
    .text {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        font-size: 14px;
        line-height: 16px;
        color: #414253;
        &.new::after {
            content: "";
            display: block;
            width: 100%;
            max-width: 20px;
            height: 20px;
            background: #4088dd;
            border-radius: 100%;
        }
    }
}

.dialog {
    width: calc(65% - 10px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__wrapper {
        max-height: 600px;
        overflow: auto;
        padding: 15px 0;
    }
    .top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
        border-bottom: 1px solid #c4c4c4;
        img {
            width: 44px;
            height: 44px;
            object-fit: cover;
            border-radius: 100%;
        }
    }
    .user_info {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #414253;
        }
    }
    .message_wrapper {
        width: 60%;
        padding: 10px 0;
        display: flex;
        align-items: flex-end;
        gap: 12px;
    }

    .from_user {
        margin-right: auto;
    }
    .from_support {
        margin-left: auto;
    }
    .date {
        font-size: 14px;
        line-height: 16px;
        color: #858585;
    }
    .text {
        padding: 15px 20px;
        background: #f7f7fe;
        border-radius: 5px;
        img {
            width: 100%;
        }
    }
    .avatar {
        width: 35px;
        height: 35px;
        object-fit: cover;
        border-radius: 100%;
    }
    .message {
        width: 100%;
        position: relative;
        &__view_time {
            position: absolute;
            right: 0;
            bottom: -20px;
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 10px;
        }
    }
    .message_field {
        padding-top: 20px;
    }
}
