.promotion_block
  width: 100%
  background: #ffffff
  border: 1px solid #dde2f8
  border-radius: 14px
  height: 100%
  position: relative
  min-height: 200px

.promotion_top
  padding: 30px 24px 27px 24px
  display: flex
  justify-content: space-between
  align-items: center
  flex-wrap: wrap

.promotion_left
  display: flex
  flex-wrap: wrap
  gap: 38px

.promotion_left_block
  display: flex
  position: relative
  z-index: 1

.promotion_top_p
  font-size: 16px
  line-height: 19px
  margin-left: 41px
  padding-top: 6px

.promotion_number
  position: absolute
  z-index: -1
  left: 38px
  bottom: 6px
  font-weight: 800
  font-size: 40px
  line-height: 50px
  color: #6FE1B8
  opacity: 0.5

.promotion_btn
  position: relative
  z-index: 33

.promotion_btn_save
  width: 196px
  height: 48px
  background: #546EDB
  border-radius: 10px
  border: 0
  display: flex
  align-items: center
  justify-content: center
  font-family: "Gilroy", sans-serif
  font-weight: 500
  font-size: 16px
  line-height: 19px
  color: #FFFFFF
  cursor: pointer
  position: relative
  transition: all 0.3s ease
  &:before
    content: ""
    background: #6FE1B8
    border-radius: 10px
    width: 200px
    height: 100%
    position: absolute
    left: -42px
    z-index: -1
  &:after
    content: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2725%27 height=%2725%27 fill=%27none%27%3E%3Cpath fill=%27%23546EDB%27 fill-rule=%27evenodd%27 d=%27M0 6.597A6.597 6.597 0 0 1 6.597 0h11.806A6.597 6.597 0 0 1 25 6.597v11.806A6.597 6.597 0 0 1 18.403 25H6.597A6.597 6.597 0 0 1 0 18.403V6.597Zm6.597-4.514a4.514 4.514 0 0 0-4.514 4.514v11.806a4.514 4.514 0 0 0 4.514 4.514h11.806a4.514 4.514 0 0 0 4.514-4.514V6.597a4.514 4.514 0 0 0-4.514-4.514H6.597ZM12.5 6.944c.575 0 1.042.467 1.042 1.042v3.472h3.472a1.042 1.042 0 0 1 0 2.084h-3.472v3.472a1.042 1.042 0 0 1-2.084 0v-3.472H7.986a1.042 1.042 0 0 1 0-2.084h3.472V7.986c0-.575.467-1.042 1.042-1.042Z%27 clip-rule=%27evenodd%27/%3E%3C/svg%3E")
    position: absolute
    left: -32px
    top: 10px

.promotion_btn_save.promotion_btn_after
  width: 212px
  &:after
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" fill="none"><path fill="%23546EDB" fill-rule="evenodd" d="M2.891 5.023C2.891 2.227 5.263 0 8.14 0h.722c2.876 0 5.248 2.227 5.248 5.023v.689c0 1.796-.979 3.357-2.441 4.244 3.076.899 5.332 3.63 5.332 6.89C17 18.057 15.977 19 14.764 19H2.236C1.023 19 0 18.057 0 16.846c0-3.26 2.256-5.991 5.332-6.89-1.462-.887-2.44-2.448-2.44-4.244v-.689ZM8.14 1.781c-1.915 0-3.426 1.473-3.426 3.242v.689c0 1.769 1.511 3.242 3.426 3.242h.722c1.915 0 3.426-1.473 3.426-3.242v-.689c0-1.769-1.511-3.242-3.426-3.242H8.14Zm-.603 9.643c-3.178 0-5.715 2.45-5.715 5.422 0 .184.164.373.415.373h12.528c.251 0 .415-.189.415-.373 0-2.973-2.537-5.422-5.715-5.422H7.536Z" clip-rule="evenodd"/></svg>')
    position: absolute
    left: -28px
    top: 14px

.promotion_bottom
  margin: 0 auto
  padding: 20px 20px
  border-top: 1px solid #DDE2F8
  border-radius: 8px 8px
  display: flex
  flex-direction: column
  align-items: center

.promotion_h2
  font-weight: 800
  text-align: center
  font-size: 32px
  line-height: 125%
  margin-bottom: 0

.promotion_h3
  font-weight: 800
  text-align: center
  font-size: 24px
  line-height: 30px
  margin-bottom: 6px

.promotion_bottom_p
  margin: 17px 0 13px

.promotion_main_p
  font-family: 'Roboto', sans-serif
  line-height: 125%
  text-align: center

.promotion_bottom_Btn
  padding-bottom: 22px
  margin-left: 30px

.bottom_flex
  border-top: 1px solid #DDE2F8
  border-radius: 8px 8px
  padding: 34px 24px 19px
  display: flex
  align-items: center
  gap: 14px
  flex-wrap: wrap

.bottom_block
  background: #FFFFFF
  border: 1px solid rgba(84, 110, 219, 0.2)
  border-radius: 10px
  width: 49%
  min-height: 125px
  display: flex
  justify-content: space-between

.bottom_block_left
  padding: 15px 12px 10px 16px

.bottom_block_right
  padding: 20px 10px 18px 18px
  outline: 2px solid rgba(84, 110, 219, 0.2)
  border-radius: 10px

.bottom_city
  font-weight: 700
  font-size: 16px
  line-height: 20px
  color: #414253
  margin-bottom: 4px

.bottom_choose_category
  font-size: 14px
  line-height: 15px
  color: #616161

.bottom_chosen_category
  font-size: 13px
  height: 70px
  overflow: hidden
  margin-top: 8px
  line-height: 13.5px
  color: #858585

.bottom_btn
  border: none
  background: #546EDB
  border-radius: 5px
  width: 187px
  height: 45px
  display: flex
  justify-content: center
  align-items: center
  color: #fff
  line-height: 19px

.bottom_block_right_sub
  display: flex
  width: 134px
  margin: 12px auto 0
  span
    font-size: 10px
    line-height: 12px
    color: #858585

.bottom_delete
  display: flex
  background: rgba(211, 33, 33, 0.2)
  border-radius: 14px
  min-width: 82px
  height: 28px
  justify-content: flex-end
  cursor: pointer
  span
    font-weight: 500
    font-size: 10px
    line-height: 12px
    color: #D32121
    align-self: center
    margin-right: 5px
    display: block
    margin-top: -2px

.create_city
  padding: 22px 23px 25px 24px

.create_title
  font-weight: 500
  font-size: 14px
  color: #000
  line-height: 17px
  margin-bottom: 8px

.category_main
  padding: 20px 20px 28px 24px
  border-top: 1px solid #DDE2F8
  border-radius: 8px

.sub_category
  padding: 28px 19px 24px 30px
  border: 1px solid #DDE2F8
  border-radius: 8px 8px 14px 14px
  display: flex
  flex-wrap: wrap
  gap: 17px

.h3
  font-weight: 500
  font-size: 15px
  line-height: 18px
  max-width: 127px
  min-height: 42px
  margin-bottom: 0

.label_sub
  display: flex
  flex-direction: column
  position: relative
  cursor: pointer

.input
  position: absolute
  opacity: 0
  cursor: pointer
  height: 0
  width: 0

.sub_image
  width: 151px

.checkmark
  position: absolute
  bottom: 33px
  left: 13px
  height: 32px
  width: 32px
  background-color: #fff
  border-radius: 50%

.label_sub:hover input ~ .checkmark:before
  background: #546EDB

.label_sub:hover input ~ .checkmark:after
  background: #546EDB
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" fill="none"><path fill="%23FFF" d="M12.925 2.548 6.184 9.266a1.319 1.319 0 0 1-.925.386H5.241a1.32 1.32 0 0 1-.243-.024l-.008-.002a1.334 1.334 0 0 1-.171-.046l-.006-.002-.012-.004a1.314 1.314 0 0 1-.485-.308l-3.37-3.36a1.314 1.314 0 0 1 .933-2.246c.35 0 .686.139.934.385L5.25 6.474 11.057.686a1.32 1.32 0 0 1 1.87-.003 1.316 1.316 0 0 1-.002 1.865Z"/></svg>')

.label_sub input ~ .checkmark:before
  content: ''
  display: block
  width: 26px
  height: 26px
  background-color: #EBEBF1
  border-radius: 100%
  position: absolute
  left: 3px
  top: 3px

.label_sub input ~ .checkmark:after
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" fill="none"><path fill="%23BEBEBE" d="M12.925 2.548 6.184 9.266a1.319 1.319 0 0 1-.925.386H5.241a1.32 1.32 0 0 1-.243-.024l-.008-.002a1.334 1.334 0 0 1-.171-.046l-.006-.002-.012-.004a1.314 1.314 0 0 1-.485-.308l-3.37-3.36a1.314 1.314 0 0 1 .933-2.246c.35 0 .686.139.934.385L5.25 6.474 11.057.686a1.32 1.32 0 0 1 1.87-.003 1.316 1.316 0 0 1-.002 1.865Z"/></svg>')
  display: block
  position: absolute
  top: 7px
  left: 9px

.label_sub input:checked ~ .checkmark:before
  background: #546EDB

.label_sub input:checked ~ .checkmark:after
  background: #546EDB
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" fill="none"><path fill="%23FFF" d="M12.925 2.548 6.184 9.266a1.319 1.319 0 0 1-.925.386H5.241a1.32 1.32 0 0 1-.243-.024l-.008-.002a1.334 1.334 0 0 1-.171-.046l-.006-.002-.012-.004a1.314 1.314 0 0 1-.485-.308l-3.37-3.36a1.314 1.314 0 0 1 .933-2.246c.35 0 .686.139.934.385L5.25 6.474 11.057.686a1.32 1.32 0 0 1 1.87-.003 1.316 1.316 0 0 1-.002 1.865Z"/></svg>')


.checkmark:after
  content: ""
  position: absolute
  display: none

.active_radio
  font-weight: 700
  font-size: 15px
  line-height: 19px
  color: #546EDB

.btn_wrapper
  margin: 28px 0 38px

.button
  background: #6FE1B8
  border-radius: 5px
  border: none
  width: 292px
  height: 58px
  display: flex
  justify-content: center
  align-items: center
  margin: auto
  font-weight: 700
  font-size: 15px
  line-height: 19px
  color: #546EDB

.promotion_create_in
  padding: 30px 28px 30px 15px

.promotion_create_in_block
  padding: 21px 30px 26px 27px
  border-radius: 10px
  border: 1px solid rgba(84, 110, 219, 0.20)
  background: #F6F7FE

.promotion_create_in_title
  margin-bottom: 16px
  display: flex
  gap: 15px
  h4
    font-size: 20px
    font-style: normal
    font-weight: 700
    margin-bottom: 0
  div
    color: #546EDB
    font-size: 15px
    font-weight: 700
    text-decoration-line: underline
    line-height: 27px
    cursor: pointer
    &:hover
      text-decoration: none

.promotion_create_in_array
  display: flex
  flex-wrap: wrap
  gap: 19px

.span_title
  color: #000
  font-size: 16px
  font-style: normal
  font-weight: 400
  display: block
  width: 215px

.container
  display: block
  position: relative
  padding-left: 31px
  margin-bottom: 4px
  cursor: pointer
  font-size: 16px
  user-select: none
  input
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0

.checkmarks
  position: absolute
  top: 0
  left: 0
  height: 22px
  width: 22px
  border-radius: 5px
  background-color: transparent
  border: 2px solid #DDE2F8

.container:hover input ~ .checkmarks
  background-color: transparent

.container input:checked ~ .checkmarks
  background-color: #546EDB
  border: 2px solid #546EDB

.checkmarks:after
  content: ""
  position: absolute
  display: none

.container input:checked ~ .checkmarks:after
  display: block

.container .checkmarks:after
  left: 6px
  top: 0
  width: 5px
  height: 12px
  border: solid white
  border-width: 0 2px 2px 0
  transform: rotate(40deg)

.subscribe_create_inBtn
  padding: 30px 0
  border-radius: 8px 8px
  border-top: 1px solid #DDE2F8
  button
    display: flex
    background: #6FE1B8
    margin: auto
    width: 292px
    height: 58px
    justify-content: center
    align-items: center
    border-radius: 5px
    border: 1px solid #6FE1B8
    color: #546edb
    font-size: 20px
    font-weight: 700
    transition: all 0.4s

@media (max-width: 900px)
  .bottom_flex
    flex-wrap: wrap
    width: 100%
  .bottom_block
    width: 100%

@media (max-width: 768px)
  .promotion_top
    padding: 20px 8px 26px
    .promotion_btn
      margin-left: 45px
      margin-top: 15px
  .promotion_left
    gap: 15px

@media (max-width: 460px)
  .bottom_block
    flex-direction: column
    height: inherit
  .bottom_block_right
    padding: 17px 10px 13px 11px
  .bottom_btn
    width: 100%
  .promotion_bottom
    padding: 22px 8px 0
    svg
      zoom: 0.8
  .promotion_h2
    font-weight: 800
    font-size: 25px
    line-height: 125%
  .promotion_h3
    font-size: 20px
    line-height: 25px
  .prom_top_p
    br:first-child
      display: none
  .sub_category
    padding: 23px 8px 30px 7px
  .button
    width: 100%
  .btn_wrapper
    margin: 15px 10px
  .create_city, .category_main
    padding: 14px 8px

@media (max-width: 420px)
  .sub_image
    width: 120px