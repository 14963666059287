.title_code
    background: #6FE1B8
    border-radius: 10px
    margin-bottom: 20px
    padding: 17px
    text-align: center
    font-weight: 700
    font-size: 20px
    line-height: 25px
    color: #546EDB
    border: none
    width: 100%
    transition: background 0.3s ease-in-out
    cursor: pointer

.place_name
    input
        &::placeholder
            background: linear-gradient(to right, rgba(0, 0, 0, 0.6) 6.8em, red 5em)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
.place_tel
    input
        &::placeholder
            background: linear-gradient(to right, rgba(0, 0, 0, 0.6) 4em, red 1em)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
.place_email
    input
        &::placeholder
            background: linear-gradient(to right, rgba(0, 0, 0, 0.6) 2.4em, red 1em)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent

.modal
    position: relative
    z-index: 100
    max-width: 450px
    left: 50%
    top: 50%
    background: #FFFFFF
    border-radius: 10px
    transform: translate(-50%, -50%)
    text-align: center
    &__top
        padding: 25px 40px
        min-height: 300px

    &__bottom
        padding: 25px 40px
        background: #F6F7FE
        border-radius: 10px
        p
            font-weight: 500
            font-size: 15px
            line-height: 18px
            margin-bottom: 15px
    .descr
        margin-bottom: 25px
        font-weight: 500
        font-size: 18px
        line-height: 22px
        span
            color: #546EDB
    .get_code
        margin-bottom: 10px
        font-weight: 500
        font-size: 14px
        line-height: 16px
        span
            color: #546EDB
    .logo
        display: flex
        justify-content: center
        margin-bottom: 30px
        svg
            width: 133px
            height: 50px
            path:first-child
                fill: #4088DD

    .promo_wrapper
        font-size: 9px
        font-style: italic
        line-height: 11px
        padding: 8px 11px 28px 12px
        position: absolute
        width: 140px
        top: -91px
        right: -1px
        background-image: url(../../../assets/images/icons/modal-info.webp)
    .phone_wrapper
        position: relative
        background: #F6F7FE
        border: 1px solid #546EDB
        border-radius: 10px
        margin-bottom: 25px
        .error
            position: absolute
            left: 0
            bottom: -20px
            color: red
            font-size: 12px
        svg
            position: absolute
            right: 18px
            top: 0
            bottom: 0
            margin: auto
        input
            border: none
            background: #F6F7FE
            padding: 10px
            border-radius: 10px
            width: 100%
            height: 100%
            font-weight: 500
            font-size: 24px
            line-height: 29px



    .btn_blue
        background: #546EDB
        border-radius: 10px
        margin-bottom: 20px
        padding: 17px
        text-align: center
        font-weight: 700
        font-size: 20px
        line-height: 25px
        color: #FFFFFF
        border: none
        width: 100%
        transition: background .3s ease-in-out
        &:hover
            background: #A7B5F2

    .btn_green
        background: #6FE1B8
        border-radius: 10px
        margin-bottom: 20px
        padding: 17px
        text-align: center
        font-weight: 700
        font-size: 20px
        line-height: 25px
        color: #546EDB
        border: none
        width: 100%
        transition: background .3s ease-in-out
        &:hover
            background: #6fe1b79f
    .terms
        font-weight: 400
        font-size: 12px
        line-height: 14px
        a
            color: #546EDB
    .loader
        position: relative
        min-height: 90px
.auth
    text-align: center
    color: #414253
    font-family: "Roboto"
    h3
        font-weight: 700
        font-size: 24px
        line-height: 28px
    .descr
        margin-bottom: 25px
        font-weight: 400
        font-size: 18px
        line-height: 21px
    .nums
        display: flex
        gap: 5px
        margin-bottom: 20px
        position: relative
        justify-content: center
    &_container
        height: 70px
    &_input
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        flex-grow: 1
        width: 74px
        flex-basis: 0
        cursor: default
        user-select: none
        box-sizing: border-box
        border-radius: 6px
        background: #F6F8F9
        border: none
        font-weight: 400
        font-size: 40px
        line-height: 47px
        color: #4088DD
        text-align: center
    .get_code
        cursor: pointer
        font-weight: 700
        font-size: 14px
        line-height: 16px
        color: #4088DD
.error
    position: absolute
    color: red
    font-size: 12px
    bottom: -15px
@media  screen and (max-width: 768px)
    .modal
        top: 56%
        max-width: 300px
        .logo
            margin-bottom: 10px
        &__top
            padding: 8px 25px
        &__bottom
            padding: 20px 25px
            p
                font-size: 10px
                line-height: 12px
        .title
            font-size: 24px
            line-height: 110%
        .descr
            font-size: 13px
            line-height: 16px
        .phone_wrapper
            margin-bottom: 10px
            border-radius: 6px

            input
                font-size: 16px
                border-radius: 6px
                line-height: 19px
        .btn_blue, .btn_green
            margin-bottom: 5px
            padding: 10px
            font-size: 14px
            line-height: 17px
            border-radius: 6px
        .terms
            font-size: 8px
            line-height: 9px
    .auth
        &_container
            width: 250px
        &_input
            font-size: 35px
            width: 50px
