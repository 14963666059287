.wrapper
    padding: 30px 0 70px 0
    text-align: center
    h1
        font-weight: 800
        font-size: 40px
        line-height: 50px
    img
        margin-bottom: 25px
        width: 100%
        object-fit: contain
        height: auto
        max-width: 836px
    .text
        margin: 0 auto
        margin-bottom: 30px
        font-weight: 400
        font-size: 24px
        line-height: 29px
        max-width: 745px
    .btn
        background: #546EDB
        border-radius: 10px
        font-weight: 700
        font-size: 20px
        line-height: 25px
        text-align: center
        color: #FFFFFF
        display: inline-block
        padding: 20px 80px
        &:hover
            background: #A7B5F2
@media  (max-width: 768px)
    .wrapper
        h1
            font-size: 30px
            line-height: 110%
        .text
            font-size: 16px
            line-height: 19px
            margin-bottom: 15px
        img
            margin-bottom: 10px
        .btn
            padding: 20px 50px
            font-size: 18px
            line-height: 22px
