.auth
    text-align: center
    color: #414253
    font-family: "Roboto"
    h3
        font-weight: 700
        font-size: 24px
        line-height: 28px
    .descr
        margin-bottom: 25px
        font-weight: 400
        font-size: 18px
        line-height: 21px
    .nums
        display: flex
        gap: 5px
        margin-bottom: 20px
        position: relative
        justify-content: center
    &_container
        height: 70px
    &_input
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        flex-grow: 1
        width: 74px
        flex-basis: 0
        cursor: default
        user-select: none
        box-sizing: border-box
        border-radius: 6px
        background: #F6F8F9
        border: none
        font-weight: 400
        font-size: 40px
        line-height: 47px
        color: #4088DD
        text-align: center
    .get_code
        cursor: pointer
        font-weight: 700
        font-size: 14px
        line-height: 16px
        color: #4088DD
.btn_blue
    max-width: 380px
    width: 100%
    height: 60px
    background: #546EDB
    border-radius: 14px
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    align-items: center
    justify-content: center
    font-family: "Gilroy", sans-serif
    font-weight: 700
    font-size: 18px
    line-height: 22px
    color: #FFFFFF
    margin: 0 auto
    margin-top: 20px
    transition: all 0.3s ease
    border: 2px solid transparent

    &:hover
        background: rgba(64, 136, 221, 0.07)
        color: #546EDB
        border: 2px solid #546EDB
