
@media screen and (max-width: 1500px)
  .cabinet_ads__right .title_box
    flex-direction: column !important
  .cabinet_ads__right .person
    margin-bottom: 45px !important
  .radio_box .radio:nth-child(2) label
    padding-left: 5px !important
  .radio_box .radio label
    padding: 10px 5px !important
  .cabinet_ads__item .title
    margin-bottom: 10px !important
  .radio_box .radio label span
    font-size: 12px !important
  .radio_box .radio label h5
    font-size: 12px !important
  .ads__descr_preview
    padding-right: 25px !important
  .cabinet_ads__item .options
    right: 10px !important
  .cabinet_ads__item .title
    font-size: 16px !important
  .banner2
    padding: 500px 0 50px !important
  .banner2 .img
    top: 35% !important
  .banner2 .bg
    width: 58% !important
  .banner2 .title
    max-width: unset !important

@media (max-width: 1025px)
  .header__top, .btn_phone, .btn_catalog
    display: none !important

@media screen and ( max-width:  1024px)
  .header
    box-shadow: none !important
    position: fixed !important
    top: 0 !important
    left: 0 !important
    z-index: 50 !important
    width: 100% !important
    height: 82px !important
  .header.fixed
    z-index: 99 !important
  .header__burger
    display: flex !important
    align-items: center !important
    justify-content: center !important
    flex-direction: column !important
    width: 40px !important
    height: 40px !important
    background: #546EDB !important
    border: 0 !important
    border-radius: 14px !important
    margin-left: 5px !important
  .header__burger span
    display: block !important
    width: 13px !important
    height: 2px !important
    border-radius: 2px !important
    background-color: #FFFFFF
    margin: 1.2px 0 !important
  .header__burger.active
    position: relative !important
  .header__burger.active span:nth-child(2)
    opacity: 0 !important
  .header__burger.active span:nth-child(1)
    position: absolute !important
    top: 50% !important
    left: 50% !important
    transform: translate(-50%, -50%) rotate(45deg) !important
  .header__burger.active span:nth-child(3)
    position: absolute
    top: 50% !important
    left: 50% !important
    transform: translate(-50%, -50%) rotate(-45deg) !important
  .main.fixedHeader
    padding-top: 147px
  .contacts__block-top
    flex-direction: column
    gap: 30px
  .cabinet_ads__right .radio_box
    width: 100% !important
  .open-admin-menu
    padding: 0
    display: flex !important
    align-items: center !important
    margin-bottom: 20px !important
    font-weight: 700 !important
    font-size: 18px !important
    line-height: 17px !important
    color: #546EDB !important
    border: 0 !important
    background-color: transparent !important
  .open-admin-menu .line
    margin-right: 8px !important
  .open-admin-menu .line span
    display: block !important
    width: 20px !important
    height: 2px !important
    background-color: #546EDB !important
    margin: 4px 0 !important
  .cabinet_ads__left
    width: 100% !important
    margin-right: 0 !important
  .cabinet_ads__left .top
    margin-bottom: 30px !important
  .cabinet_ads__left h6
    margin-bottom: 39px !important
  .cabinet_ads__left .btn_save
    top: 15px !important
    right: 20px !important
  .cabinet_ads__left .top .item
    width: 100% !important
    margin-right: 0 !important
    margin-bottom: 6px !important
  .cabinet_ads__right
    width: 100% !important
  .cabinet_ads__left .btn_save
    width: 120px !important
  // .cabinet_ads__right
  //   position: fixed !important
  //   top: 82px !important
  //   left: -101% !important
  //   z-index: 9 !important
  //   overflow-y: auto !important
  //   width: 100% !important
  //   height: calc(100% - 82px) !important
  //   background: #F3F4F9 !important
  // .cabinet_ads__right .img
  //   height: 230px !important
  //   margin-bottom: 11px !important
  // .cabinet_ads__right .img img
  //   width: auto !important
  //   height: 100% !important
  // .cabinet_ads__right .title_box
  //   flex-direction: column !important
  // .cabinet_ads__right .title
  //   margin-bottom: 20px !important
  // .cabinet_ads__right .content .status
  //   height: 40px !important
  //   margin-bottom: 26px !important
  // .cabinet_ads__right .content .status svg
  //   left: 13px !important
  //   width: 18px !important
  //   height: 18px !important
  // .cabinet_ads__right .person
  //   margin-bottom: 46px !important
  // .cabinet_ads__right .buttons
  //   flex-direction: column !important
  // .cabinet_ads__right .buttons .edit,
  // .cabinet_ads__right .buttons .deactivate
  //   margin-bottom: 34px !important
  // .cabinet_ads__right .content
  //   padding: 0 15px 35px 13px !important
  .cabinet_ads__right .wrap
    background: #F3F4F9 !important
  .cabinet_ads__item .img
    width: 150px !important
  .cabinet__aside_close
    display: block !important
    width: 30px !important
    height: 30px !important
    position: absolute !important
    top: 10px !important
    right: 20px !important
    background-color: transparent !important
    border: 0 !important
    z-index: 33
  .cabinet__aside_close::after
    content: "" !important
    width: 30px !important
    height: 2px !important
    background-color: #546EDB !important
    border-radius: 3px !important
    position: absolute !important
    z-index: 100 !important
    top: 50% !important
    left: 50% !important
    transform: translate(-50%, -50%) rotate(45deg) !important
  .cabinet__aside_close::before
    content: "" !important
    width: 30px !important
    height: 2px !important
    background-color: #546EDB !important
    border-radius: 3px !important
    position: absolute !important
    top: 50% !important
    left: 50% !important
    transform: translate(-50%, -50%) rotate(-45deg) !important
  .cabinet_ads__right .buttons
    flex-direction: column !important
  .program__wrapper
    flex-direction: column !important
  .program__box
    flex-direction: column !important
    gap: 20px !important
  .advantages .iphone
    position: absolute !important
    width: 345px !important
    right: 110px !important
    bottom: -15px !important

@media screen and (max-width: 900px)
  .slider_title
    font-size: 40px !important
    line-height: 34px !important
  .slider_information .slider_text
    font-size: 36px !important
    line-height: 49px !important
  .banner__bottom .img
    width: 190px !important
    height: 170px !important

@media screen and (max-width: 768px)
  .modal .box
    flex-direction: column !important
  .modal .box .left, .modal .box .right
    width: 100% !important
  .modal .input .input_box input
    width: 137px !important
  .radio_box .radio:nth-child(2) label
    padding-left: 12px !important
  // section
  //   display: none !important
  .container
    padding: 0 13px !important
  .header__top, .btn_phone, .btn_catalog
    display: none !important
  .header__navbar
    position: fixed !important
    top: 81px !important
    left: 0 !important
    width: 100% !important
    z-index: 99 !important
    height: 0 !important
    background-color: #fff !important
    overflow: hidden !important
    transition: all 0.3s ease !important
  .header__navbar .ul
    width: 100% !important
    flex-direction: column !important
    align-items: flex-start !important
    padding: 16px !important
  .header__navbar .ul .li
    width: 100% !important
  .header__navbar .item
    display: block !important
    margin-left: 0 !important
    margin-bottom: 16px !important
    font-size: 20px !important
  .header__navbar .btn_phone
    display: inline-block !important
    margin-bottom: 18px !important
    text-align: center !important
    margin-left: 16px !important
  .header__navbar .btn_catalog
    display: inline-block !important
    margin-left: 16px !important
  .header__navbar.active
    height: calc(100% - 81px) !important
    overflow-y: auto !important
  .header__bottom
    height: 82px !important
    padding-top: 12px !important
  .btn_search
    width: 40px !important
    height: 40px !important
    margin-left: auto !important
  .btn_search svg
    width: 15px !important
  .header__logo
    width: 80px !important
    height: 30px !important
  .banner
    display: block !important
    height: 335px !important
  .banner__block
    height: 335px !important
  .banner__content
    padding-top: 60px !important
  .banner__content .title
    font-size: 16px !important
    line-height: 20px !important
    margin-bottom: 24px !important
  .banner__content .title span
    font-size: 16px !important
    line-height: 20px !important
    display: block !important
    font-weight: 300 !important
  .banner__content .switching
    width: 210px !important
    height: 40px !important
  .banner__content .switching .btn
    width: 96px !important
    height: 30px !important
    font-size: 14px !important
    line-height: 16px !important
  .banner__bottom
    display: flex !important
    margin-top: -50px !important
    width: calc(100% - 14px) !important
    height: 125px !important
    padding-top: 25px !important
    margin-bottom: 34px !important
  .banner__bottom .title
    font-size: 12px !important
    line-height: 14px !important
  .banner__bottom .block
    width: 33.333% !important
  .banner__bottom .img
    width: 65px !important
    height: 50px !important
    margin-bottom: 6px !important
  .banner__bottom .block::after
    height: 22px !important
  .brigades
    display: block !important
    padding-top: 0 !important
    padding-bottom: 10px !important
  .brigades__box
    flex-direction: column !important
  .brigades__block
    width: 100% !important
    margin-bottom: 30px !important
  .brigades__block .img
    height: 230px !important
    margin-bottom: 10px !important
  .brigades__block .title
    font-size: 14px !important
    line-height: 17px !important
  .banner2
    display: block !important
    height: unset !important
    padding-top: 345px !important
    overflow: hidden !important
  .banner2 .img
    width: 110% !important
    right: -15% !important
  .banner2 .bg
    width: 80% !important
  .banner2 .title
    padding-left: 10px !important
    font-size: 20px !important
    line-height: 134.75% !important
  .banner2 .title span
    display: block !important
  .technique
    display: block !important
    padding-top: 47px !important
    padding-bottom: 38px !important
  .technique__block
    width: 100% !important
    height: 550px !important
    border-radius: 10px !important
    overflow: hidden !important
  .technique__block .img
    height: 182px !important
    overflow: hidden !important
    position: relative !important
  .technique__block .img img
    position: absolute !important
    top: 50% !important
    left: 50% !important
    transform: translate(-50%, -50%) !important
    height: auto !important
    width: 100% !important
  .technique__block .content
    padding: 7px 7px 24px 14px !important
  .technique__block .title_box
    margin-bottom: 15px !important
  .technique__block .title
    font-size: 16px !important
    line-height: 19px !important
  .technique__block .status
    padding: 8px 18px 8px 13px !important
    font-size: 12px !important
    line-height: 14px !important
  .technique__block .status svg
    width: 14px !important
    height: 14px !important
    margin-right: 11px !important

  .technique__block .text
    margin-bottom: 25px !important
  .advantages
    display: block !important
    overflow: hidden !important
    padding-top: 40px !important
  .advantages__box
    flex-direction: column !important
    padding-top: 30px !important
    padding-bottom: 60px !important
  .advantages__block
    width: 100% !important
    display: -webkit-box !important
    display: -ms-flexbox !important
    display: flex !important
    align-items: center !important
    margin-bottom: 20px !important
  .advantages__block .icon
    min-width: 60px !important
    width: 60px !important
    height: 60px !important
    border-radius: 14px !important
    margin-right: 18px !important
    margin-bottom: 0 !important
  .advantages__block .icon svg
    width: 26px !important
    height: 26px !important
  .advantages__block .title
    font-size: 16px !important
    line-height: 19px !important
  .program__text .text
    font-size: 12px !important
    line-height: 14px !important
  .program__text h6
    font-size: 12px !important
    line-height: 14px !important
    margin-bottom: 90px !important
  .program
    padding-bottom: 0 !important
  .program__box
    flex-direction: column !important
    margin-bottom: 40px
  .program__box .link
    width: 95px !important
    height: 34px !important
    margin: 0 !important
    margin-bottom: 6px !important
  .program__box .link img
    width: 80px !important
  .advantages .iphone
    width: 270px !important
    right: -7px !important
    bottom: -11px !important
    left: auto !important
  .rent_terms
    overflow: hidden !important
    display: block !important
    padding-top: 30px !important
    padding-bottom: 0 !important
  .rent_terms__box
    flex-direction: column !important
  .rent_terms__img
    order: 1 !important
    height: 310px !important
    width: 120% !important
    margin-left: -10% !important
  .rent_terms__img img
    width: 100% !important
  .rent_terms__content
    width: 100% !important
    margin-left: 0 !important
    padding-top: 0 !important
    padding: 0 10px !important
  .rent_terms__content .title
    font-size: 16px !important
    line-height: 134.75% !important
    margin-bottom: 20px !important
  .rent_terms__content .text
    font-size: 14px !important
    line-height: 16px !important

  .technique__wrap
    width: 100% !important
    margin-bottom: 20px !important

  .pagination
    margin-top: 25px !important
  .pagination .link
    width: 30px !important
    height: 30px !important
    border-radius: 10px !important
    margin: 0 2px !important
    font-size: 14px !important
    line-height: 16px !important
  .pagination span
    width: 45px !important
    margin: 0 9px !important

  .technique .slick-prev
    left: -8px !important
  .technique .slick-next
    right: -8px !important
  .contacts
    display: block !important
  .contacts__map
    height: 555px !important
  .contacts__map img
    height: 100% !important
    width: auto !important
  .contacts__block
    width: calc(100% - 26px) !important
    padding: 24px 24px 34px 24px !important
  .contacts__wrap
    padding-bottom: 10px !important
  .contacts__block .title
    font-size: 20px !important
    line-height: 134.75% !important
    margin-bottom: 19px !important
  .contacts__block .h5
    font-size: 14px !important
    line-height: 134.75% !important
    margin-bottom: 0 !important
  .contacts__block .text
    font-size: 14px !important
    line-height: 134.75% !important
    margin-bottom: 19px !important
  .contacts__block .logo
    position: relative !important
    width: 126px !important
    top: auto !important
    left: auto !important
    bottom: auto !important
    right: auto !important
  .contacts__block .box
    flex-direction: column !important
  .contacts__block .box .h6
    font-size: 10px !important
    line-height: 13px !important
    margin-bottom: 0 !important
  .contacts__block .box .email, .contacts__block .box .phone
    font-size: 14px !important
    line-height: 18px !important
  .contacts__block .box .wrap
    margin-bottom: 19px !important
  .contacts__block .left
    width: 100% !important
    margin-bottom: 30px !important
  .contacts__block .right
    margin-left: 0 !important
  .contacts__block:last-child .title
    margin-bottom: 34px !important
  .contacts__block:last-child .text
    margin-bottom: 15px !important
  .packages
    display: block !important
    padding-top: 23px !important
    padding-bottom: 26px !important
  .packages__box
    flex-direction: column !important
  .packages__block
    width: 100% !important
    margin-bottom: 10px !important
  .packages__text
    font-size: 10px !important
    line-height: 12px !important
    margin-top: -20px !important
  .packages__title
    font-size: 12px !important
    line-height: 14px !important
    margin-bottom: 23px !important
  .packages__box
    margin-bottom: 50px !important
  .login
    display: block !important
    padding-bottom: 37px !important
  .login__banner
    height: 316px !important
  .login__block
    height: auto !important
    margin-top: -60px !important
    width: calc(100% - 26px) !important
    padding: 0 10px !important
    padding-top: 24px !important
    padding-bottom: 40px !important
    border-radius: 20px !important
  .login__box
    flex-direction: column !important
  .login__registration
    max-width: 300px !important
    margin-top: 67px !important
    margin-bottom: 100px !important
  .login__registration .input
    margin-bottom: 20px !important
  .login__block .links
    padding: 0 28px !important
    justify-content: flex-start !important
  .login__block .links .link
    font-weight: 400 !important
    font-size: 20px !important
    line-height: 134.75% !important
    margin-right: 30px !important
  .login__block .links .link.active
    font-size: 20px !important
    line-height: 134.75% !important
    font-weight: 800 !important
  .login__entrance
    max-width: 300px !important
    margin-top: 48px !important
    margin-bottom: 84px !important
  .login__block .input
    width: 100% !important
  .login__btn
    max-width: 300px !important
    height: 50px !important
    font-size: 14px !important
    line-height: 17px !important
    border-radius: 10px !important
  .login__code
    margin-top: 40px !important
    margin-bottom: 58px !important
    max-width: 300px !important
  .login__code .title
    text-align: start !important
  .login__code .text
    font-size: 14px !important
    line-height: 16px !important
    text-align: start !important
    margin-bottom: 38px !important
  .login__code .link_box
    justify-content: flex-start !important
  .cabinet
    display: block !important
  .cabinet__person
    padding-left: 18px !important
  .cabinet__item
    padding-left: 18px !important
    padding-right: 18px !important
  .exit_link
    padding-left: 18px !important
  .person_data
    padding: 20px 35px 20px 25px !important
  .person_img
    margin: 0 auto !important
    // margin-bottom: 45px !important
  .person_data .box
    flex-direction: column !important
  .person_data .left
    width: 100% !important
    margin-right: 0 !important
  .person_data .right
    width: 100% !important
    padding-top: 0 !important
  .person_data .box_input .right
    padding-top: 0 !important
  .person_data .creation .title
    font-size: 14px !important
  .person_data .creation .box
    flex-direction: row !important
  .person_data .creation
    margin-bottom: 10px !important
  .person_data .box .right
    padding-top: 0 !important
  .person_data .btn_save
    position: relative !important
    top: auto !important
    right: auto !important
    width: 100% !important
    margin-top: 16px !important
  .cabinet_ads__item .options
    top: 15px !important
    right: 6px !important
  .cabinet_ads__item .title
    margin-top: 13px !important
    margin-bottom: 21px !important
  .cabinet_ads__item .radio_box
    width: 140px !important
    flex-direction: column !important
  .cabinet_ads__item .radio_box .radio
    width: 100% !important
  .cabinet_ads__item .radio_box .radio label
    border-radius: 0 !important
  .cabinet_ads__item .radio_box .radio:nth-child(1) label
    border-radius: 10px 10px 0 0 !important
  .cabinet_ads__item .radio_box .radio:nth-child(2) label
    border-radius: 0 0 10px 10px !important
  .cabinet_ads__item .title
    font-size: 14px !important
    line-height: 16px !important
  .cabinet_ads__date
    border-radius: 14px
    position: absolute !important
    background: #fff !important
    width: 100% !important
    top: 0 !important
    height: 100% !important
    z-index: 100 !important
    transform: translateX(-120%)
    transition: transform 0.3s ease-in-out
  .cabinet_ads__date .timing
    flex-wrap: wrap !important
    justify-content: space-between !important
  .cabinet_ads__date .timing .btn,
  .cabinet_ads__date .timing .btn2
    width: 100% !important
    order: 1 !important
  .cabinet_ads__date .timing h5
    margin: 0 !important
  .cabinet_ads__date .wrap
    min-height: auto !important
  .regulations__content
    width: 100% !important
    min-height: 680px !important
    padding: 26px 20px 26px 26px !important
  .regulations__content .box .img
    display: none !important
  .regulations__content .text2
    width: 100% !important
  .regulations__content .text2
    text-align: justify !important
  .cabinet__chat
    width: 100% !important
    height: 680px !important
  .cabinet__chat__wrap
    height: 520px !important
    padding-bottom: 10px !important
  .cabinet__chat .form .input
    width: 250px !important
  .cabinet__chat .form .btn
    min-width: 65px !important
  .cabinet__chat__wrap .chat_date
    margin-top: 60px !important
    margin-bottom: 16px !important
  .cabinet__chat__wrap .chat_right
    margin-top: 80px !important
  .cabinet__chat__wrap .chat_left
    margin-top: 0 !important
  .cabinet__chat__wrap .chat_right
    max-width: 80% !important
  .cabinet__chat__wrap .chat_left
    max-width: 80% !important
  .create_ads__left
    position: fixed !important
    top: 82px !important
    left: -101% !important
    z-index: 9 !important
    width: 100% !important
    height: calc(100% - 82px) !important
    margin-right: 0 !important
    form
      width: 100%
  .create_ads__box > div
    flex-wrap: wrap
  .create_ads__center
    width: 100% !important
    margin-right: 0 !important
  .input_foto_div
    width: 100% !important
    margin-right: 0 !important
  .input_wrap
    width: 100% !important
    margin-bottom: 10px !important
  .input_wrap .icon
    width: 24px !important
  .input_foto_wrap
    margin-bottom: 29px !important
    flex-wrap: wrap !important
    height: auto !important
  .input_foto_img
    margin-top: 10px !important
  .select_wrap
    margin-bottom: 10px !important
  .create_ads__center .btn_publish
    width: 140px !important
    right: 8px !important
    top: 12px !important
    font-size: 14px !important
    line-height: 17px !important
  .input_foto_wrap input
    width: 100% !important
  .create_ads__right
    min-height: auto !important
    position: fixed !important
    top: 82px !important
    left: 0 !important
    z-index: 9 !important
    width: 100% !important
    height: calc(100% - 82px) !important
    overflow-y: auto !important
    padding: 28px 22px 20px 22px !important
  .create_ads__right .title
    padding: 0 !important
  .look-calendar
    max-width: 285px !important
  .create_ads__right .btn_save
    max-width: 100% !important
    position: relative !important
    bottom: auto !important
    left: auto !important
    transform: translate(0) !important
  .btn_check
    max-width: 100% !important
    margin-bottom: 130px !important
  .input_add_file input
    width: 100% !important
  .cabinet_responses
    width: 100% !important
    padding: 20px 15px !important
  .cabinet_responses__block
    width: 100% !important
    margin-right: 0 !important
  .cabinet__content__title
    margin-bottom: 27px !important
    flex-direction: column !important
  .cabinet__content__title div
    width: 100% !important
    display: flex !important
    justify-content: space-between !important
  .cabinet__content__title .title
    margin-bottom: 0 !important
  .regulations__left
    position: fixed !important
    top: 82px !important
    left: -101% !important
    z-index: 9 !important
    width: 100% !important
    height: calc(100% - 82px) !important
    overflow-y: auto !important
    padding: 28px 15px !important
    margin-left: 0 !important
  .regulations__content
    width: 100% !important
    padding: 32px 11px 36px 20px !important
  .cabinet__notifications__aside
    position: fixed !important
    top: 82px !important
    left: -101% !important
    z-index: 9 !important
    width: 100% !important
    height: calc(100% - 82px) !important
    margin-right: 0 !important
    overflow-y: auto !important
    padding-bottom: 0 !important
  .cabinet__notifications__aside .wrap
    padding: 20px 15px !important
    height: auto !important
  .cabinet__notifications__aside .title
    margin-bottom: 0 !important
  .cabinet__notifications__content
    width: 100% !important
    height: auto !important
    padding: 20px 27px 75px 25px !important
  .cabinet_favorites
    width: 100% !important
    padding: 28px 10px 20px 10px !important
  .cabinet_favorites .top
    flex-direction: column !important
    max-width: 310px !important
    margin: 0 auto !important
    margin-bottom: 9px !important
  .cabinet_favorites .top .item
    width: 100% !important
    margin-bottom: 6px !important
  .cabinet_favorites h6
    max-width: 310px !important
    margin: 0 auto !important
    margin-bottom: 40px !important
  .technique__block .content
    padding: 7px 7px 25px 12px !important
  .technique__block .rating
    height: 37px !important
    padding: 0 15px !important
    font-size: 16px !important
    line-height: 19px !important
  .technique__block .rating svg
    width: 18px !important
    height: 18px !important
    margin-right: 12px !important
  .cabinet__content_switch
    margin-top: 12px !important
  .card
    display: block !important
    padding-top: 0 !important
    padding-bottom: 20px !important
  .card .container
    padding: 0 !important
  .card__box
    flex-direction: column !important
  .card__img
    width: 100% !important
    height: 230px !important
  .card__content
    width: 100% !important
    padding: 0 20px 0 15px !important
    padding-top: 11px !important
  .card__content .title
    max-width: 278px !important
    width: 100% !important
    font-size: 16px !important
    line-height: 134.75% !important
    margin-bottom: 20px !important
  .card__content .text
    font-size: 14px !important
    line-height: 16px !important
    margin-bottom: 20px !important
  .card .radio_box
    max-width: 100% !important
    margin-bottom: 20px !important
  .card__right .status
    width: 100% !important
    justify-content: center !important
    position: relative !important
  .card__right .status svg
    position: absolute !important
    top: 50% !important
    left: 13px !important
    transform: translateY(-50%) !important
  .card__right .status__box
    margin-bottom: 20px !important
    order: -2 !important
  .card__right
    width: 100% !important
    padding: 0 20px 0 15px !important
    display: flex !important
    flex-direction: column !important
  .card__right .btns
    justify-content: space-between !important
    order: -1 !important
    margin-bottom: 18px !important
  .card__right .btns .btn1
    width: 183px !important
    height: 50px !important
    font-size: 14px !important
    line-height: 17px !important
  .card__right .btns .btn2
    width: 146px !important
    height: 50px !important
    font-size: 14px !important
    line-height: 17px !important
  .card__right .person .h5
    display: none !important
  .card__right .person
    padding-left: 0 !important
  .card__right .person__box
    margin-bottom: 0 !important
    padding: 10px 12px 10px 23px !important
    background: #FFFFFF !important
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important
    border-radius: 10px !important
  .card__right .person__img
    width: 40px !important
    height: 40px !important
    margin-right: 17px !important
  .person__content
    width: calc(100% - 57px) !important
    display: -webkit-box !important
    display: -ms-flexbox !important
    display: flex !important
    align-items: center !important
    justify-content: space-between !important
  .card__right .person__content .name
    margin: 0 !important
    font-size: 14px !important
    line-height: 16px !important
  .advantages.card .container
    padding: 0 13px !important
  .feedback
    display: block !important
    padding-top: 30px !important
    padding-bottom: 20px !important
  .feedback .container
    padding-right: 0 !important
  .feedback__block
    width: 267px !important
    height: 270px !important
    transform: translateX(30%) !important
    margin-right: 13px !important
    padding: 14px 15px 20px 22px !important
  .feedback__block .top .stars
    display: none !important
  .feedback__block .top .person .img
    width: 30px !important
    height: 30px !important
    margin-right: 16px !important
  .feedback__block .top
    padding-bottom: 20px !important
    margin-bottom: 20px !important
  .feedback__block .top .person .name
    font-size: 14px !important
    line-height: 16px !important
    margin: 0 !important
    margin-bottom: 3px !important
  .feedback__block .top .person .date
    font-size: 12px !important
    line-height: 14px !important
  .feedback__block .text
    font-size: 14px !important
    line-height: 16px !important
    margin-bottom: 20px !important
  .feedback__block .img_bottom
    width: 81px !important
    height: 75px !important
  .card__img .rating
    bottom: 19px !important
    top: auto !important
  .title_h3
    font-size: 16px !important
    line-height: 134.75% !important
  .slider_information .slider_title
    font-size: 28px !important
    line-height: 20px !important
  .slider_information .slider_text
    font-size: 28px !important
    line-height: 28px !important
  .slider_wrap .slick-slider img
    height: 500px !important
  .slider_wrap .slick-dots
    bottom: 65px !important
    right: 15px !important
  .modal__card3 .bordered_bottom
    margin: 0 -40px 14px
    margin-left: -30px
    margin-right: -33px
    padding: 14px 40px 6px
  .slick_List
    width: 100%
  .modal__card3
    padding: 20px 33px 20px 30px
    .request_sub_text
      margin-bottom: 10px
    .title
      margin-bottom: 8px
    .datePick
      padding-left: 72px
    .datePick .calendarRequestSvg
      width: 60px
      .calendarSvg
        padding-left: 20px
    .payment_req_wrap input
      padding-left: 50px !important
      font-size: 14px
    .payment_req_wrap .payment_req_right
      right: 6px
      top: 10px
    .input textarea
      margin-bottom: 10px
    .request_time input
      padding-left: 48px

@media screen and (max-width: 416px)
  .slider_wrap .slick-slider .slick-dots
    display: none !important
  .request_address_svg
    position: absolute
    right: 14px
    top: 6px
    zoom: 0.78
  .slider_information .slider_title,
  .slider_information .slider_text
    font-size: 16px !important
    line-height: 20px !important
  .slider_information .slider_toggle .btn
    width: 96px !important
    height: 30px !important
    font-size: 14px !important
    line-height: 16px !important
    padding: 0 !important
  .slider_information .slider_toggle
    width: 210px !important
  .slider_wrap .slick-slider img
    height: 290px !important
  .slider_information .slider_text
    margin-bottom: 16px !important
  .banner__bottom
    border-radius: 20px !important
  .cabinet_ads__item .radio_box
    width: 110px !important
  .radio_box .radio label
    padding: 5px !important
  .cabinet_ads__item .title
    margin-bottom: 10px !important
  .radio_box .radio:nth-child(2) label
    padding-left: 5px !important
  .banner2
    padding-top: 260px !important
    padding-bottom: 10px !important
  .banner2 .img
    top: 25% !important
  .datePick
    min-height: 31px
    font-size: 12px
    padding: 6px
  .modal__card3 .nice-dates-day
    height: inherit !important
  .modal__card3 .nice-dates-grid
    height: 230px !important
  .nice-dates-grid_container.-origin-top
    height: 35px !important
  .modal__card3 .datePick
    padding-left: 60px
  .modal__card3
    width: 290px
    padding: 20px 25px
    .closeModal
      right: 22px
      top: 21px
      zoom: 0.7
    .modal .title
      font-size: 23px
    .request_sub_text
      font-size: 12px
      line-height: 15px
      text-align: left
    .input h6
      margin-bottom: 4px
    .datePick .calendarRequestSvg
      width: 50px
    .datePick .calendarRequestSvg .calendarSvg
      padding-left: 22px
      zoom: 0.8
    .input_margin_15
      margin-bottom: 10px
    .input .input_box input
      width: 112px !important
    .input input
      height: 31px
      font-size: 11px
    .request_time .time_request_left, .payment_req_wrap .payment_req_left
      width: 26px
      font-size: 11px
    .request_time input
      padding-left: 40px
    .payment_req_wrap input
      padding-left: 32px !important
    .payment_req_wrap .payment_req_right
      right: 8px
      top: 8px
      zoom: 0.7
    .input textarea
      width: 238px
      min-width: 238px
      height: 94px
      min-height: 94px
      max-height: 94px
      padding: 9px 11px
      font-size: 12px
    .bordered_bottom
      margin-left: -25px
      margin-right: -25px
    .bordered_bottom
      padding: 16px 25px
    .modal__btn
      width: 238px
      height: 38px
      font-size: 12px
      line-height: 16px
      border-radius: 6px

@media screen and (max-width: 320px)
  .slider_information .slider_title
    font-size: 14px !important
    line-height: 4px !important
