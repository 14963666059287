.ReactCrop
    border-radius: 10px !important
    margin-bottom: 25px
.ReactCrop__crop-selection
    border: none !important
    box-shadow: 0 0 0 9999em rgb(0 0 0 / 75%) !important
    border-radius: 100% !important
.ReactCrop__drag-handle::after
    background-color: #6FE1B8 !important
    width: 16px !important
    border: none !important
    height: 16px !important
.ReactCrop .ord-ne::after
    top: 5px !important
    right: 5px !important
.ReactCrop .ord-se::after
    bottom: 5px !important
    right: 5px !important
.ReactCrop .ord-sw::after
    bottom: 5px !important
    left: 5px !important
.ReactCrop .ord-nw::after
    top: 5px !important
    left: 5px !important

@media screen and ( max-width: 576px)
.ReactCrop
    margin-bottom: 15px !important
    border-radius: 6px !important
.ReactCrop__drag-handle::after
    width: 11px !important
    height: 11px !important
