.wrapper
    display: flex
    gap: 25px
    padding: 50px 0

.grid_container
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 25px
    background: #ffffff
    border-radius: 15px
    width: 100%

@media screen and (max-width: 1200px)
    .wrapper
        gap: 10px
        padding: 25px 0
        min-height: 300px
        position: relative

    .grid_container
        background: none
