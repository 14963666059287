.wrapper
    background: #ffffff
    width: 100%
    border-radius: 8px
    position: relative
    font-weight: 700
    font-size: 13px
    line-height: 16px
    text-transform: uppercase
    text-align: left
    padding: 20px
    transition: all 0.3s ease-in-out
    font-family: "Gilroy"
    &_correct
        border: 1px solid #3fc092
        color: #3fc092
        margin-bottom: 10px
        &.active
            background: #3fc092
            color: #fff

        &:hover
            background: #3fc092

    &_incorrect
        border: 1px solid #9a2828
        color: rgba(154, 40, 40, 1)
        &:hover
            background: #d32121

    &_change
        border: 1px solid #4088dd
        color: #4088dd
        background: #fff
        &:hover
            color: #fff
            background: #4088dd

    span
        position: absolute
        right: 0
        top: 0
        height: 100%
        border-radius: 5px
        display: flex
        align-items: center
        padding: 11px

    &:hover
        color: #fff

.correct
    background: #289a71

.incorrect
    background: #9a2828

.change
    background: #4088dd

.onPublic
    padding: 25px 0
    background-color: #546edb
    border-radius: 14px
    text-align: center
    background-image: url(../../assets/images/moderator/cursors.png)
    background-repeat: no-repeat
    button
        width: 30%
        padding: 15px
        background: #ffffff
        border-radius: 5px
        border: none
        font-weight: 700
        font-size: 24px
        line-height: 30px
        color: #546edb
        transition: all 0.3s ease-in-out
        &.disabled
            filter: grayscale(1)
            &:hover,
            &:active
                background: #ffffff
                color: #546edb
                outline: none
                cursor: default
        &:hover,
        &:active
            background: #546edb
            color: #fff
            outline: 2px solid #fff
@media screen and (max-width: 1200px)
    .wrapper
        padding: 15px 5px
    .onPublic
        background-size: 100%
        margin-bottom: 40px

@media screen and (max-width: 768px)

    .onPublic
        background-image: none
        padding: 25px
        button
            width: 100%
