.bank__checks__top, .bankChecks__wallet__data, .bankChecks_wallet
  display: flex

.bankChecks__wallet__data, .bankChecks_wallet
  gap: 10px

.bankChecks__wallet__data, .bankChecks_wallet
  flex-basis: 50%

.bank__checks__top
  border-bottom: 1px solid #DDE2F8

.bankChecks__wallet__data
  padding: 22px 12px 20px 26px

.title__data_wallets
  font-weight: 500
  font-size: 16px
  line-height: 19px

.wallet_data__data
  font-weight: 700
  font-size: 28px
  line-height: 35px

.wallet__data
  flex-basis: 48%
  height: 92px
  background: #5075DC
  border-radius: 8px
  color: #fff
  padding: 19px 30px 18px 17px
  &.data__points
    background: #47BD93

.bankChecks_wallet
  background: #5075DC
  border-radius: 0 14px 0 0
  align-items: center
  justify-content: center
  .bank_checks_wallet_btn
    background: #FFFFFF
    box-shadow: 5.58242px 6.51282px 12.0952px #3F59C7, -7.44322px -5.58242px 10.2344px rgba(255, 255, 255, 0.25)
    border-radius: 80px
    border: none
    height: 65px
    display: flex
    align-items: center
    padding: 0 45px
    font-weight: 600
    font-size: 16.7473px
    line-height: 21px
    color: #546EDB
    transition: all 0.4s
    &:hover
      box-shadow: none
    svg
      margin-right: 7.55px

.bankCheckBottom
  padding: 25px 24px 26px 24px

.bankCheckFilter
  display: flex
  gap: 20px
  margin-bottom: 35px
  align-items: flex-end
  justify-content: space-between

.bankCheckBtn, .bankCheck_block
  button
    width: 175px
    height: 48px
    background: #546edb
    border-radius: 10px
    display: flex
    justify-content: center
    align-items: center
    font-size: 18px
    line-height: 22px
    color: #fff
    border: none
    font-weight: 500
    transition: all 0.4s
    //&:hover
    //  background: #FFFFFF
    //  border: 1px solid #546edb
    //  color: #546edb

.bankCheck_block
  button
    width: 185px
    background: #6FE1B8
    color: #546edb

.bankFilter__title
  color: #000
  font-weight: 600
  font-size: 14px
  line-height: 17px
  margin-bottom: 8px

.date_range_flex
  display: flex
  input
    max-width: 111px
    height: 48px
    background: #F6F7FE
    border: 1px solid rgba(84, 110, 219, 0.2)
    border-radius: 10px 0 0 10px
    padding: 0 10px
    &.input_end
      border-radius: 0 10px 10px 0
      border-left: none
    &::placeholder
      font-family: 'Roboto'
      font-weight: 400
      font-size: 15px
      line-height: 18px
      color: rgba(0, 0, 0, 0.4)

.bankCheckStatus
  width: 70%

.bankCheckCalendar
  .nice-dates-popover
    max-width: 440px

.bankCheckTable
  border: 1px solid rgba(84, 110, 219, 0.2)
  border-radius: 10px
  .bank_check__table_top
    background: #F6F7FE
    display: flex
    justify-content: space-between
    border-radius: 10px
    padding: 16px 23px 14px
    border-bottom: 1px solid rgba(84, 110, 219, 0.2)
    > div
      font-weight: 500
      font-size: 15px
      line-height: 18px
  .bankCheck__title
    flex-basis: 30%
  .bankCheck_sum
    flex-basis: 35%
  .bankCheck__status
    flex-basis: 22%

.bank__check__block
  display: flex
  justify-content: space-between
  align-items: center
  padding: 11px
  padding-left: 23px
  border-bottom: 1px solid rgba(84, 110, 219, 0.2)
  &:last-child
    border-bottom: none

.check_status
  font-weight: 500
  font-size: 14px
  line-height: 17px

.applied_check
  color: #3B9A79

.waiting_check
  color: #5075DC

.denied_check
  color: red

.bank__check__block_sum
  font-weight: 400
  font-size: 14px
  line-height: 17px
  color: #000000

.bank__check__block_dates
  .dates_check_data
    color: #2E3644
    font-size: 14px
    line-height: 17px
  .dates_check_time
    font-size: 12px
    line-height: 14.4px
    color: #9E9E9E

.bank__check__block_dates
  flex-basis: 30%
.bank__check__block_sum
  flex-basis: 35%
.bank__check__block_status
  flex-basis: 23%
.bankchecks_tabs
  display: flex
  gap: 15px
  margin-bottom: 30px
  font-size: 20px
  font-weight: 500
  p
    opacity: 0.7
    cursor: pointer
    &.active
      font-weight: 600
      opacity: 1
      color: #5075DC
@media (max-width: 580px)
  .bank__checks__top
    flex-wrap: wrap
    .bankChecks__wallet__data, .bankChecks_wallet
      flex-basis: 100%
  .bankChecks_wallet
    height: 86px
    border-radius: 10px
  .bankChecks__wallet__data
    padding: 9px 10px
  .bank__checks__top
    border-radius: 10px
  .title__data_wallets
    font-weight: 500
    font-size: 12px
    line-height: 15px
  .wallet_data__data
    font-weight: 700
    font-size: 22px
    line-height: 27px
  .wallet__data
    height: 80px
  .bankChecks_wallet .bank_checks_wallet_btn
    font-weight: 600
    font-size: 15px
    line-height: 18px
    width: 100%
    padding: 0
    justify-content: center
    margin: 0 10px
    height: 52px
    svg
      zoom: 0.85
  .bankCheckFilter
    flex-wrap: wrap
  .bankCheckStatus
    width: 100%
  .bankCheckCalendar .nice-dates-popover
    max-width: 85%
  .date_range_flex input
    max-width: 100%
  .bankCheckBottom
    padding: 18px 6px 80px 6px
  .bankCheckBtn, .bankCheck_block
    width: 100%
    button
      width: 100%
  .bankCheckCalendar
    width: 100%
  .block_calendar
    width: 50%
  .date_range_flex input
    width: 100%
  .bankCheckFilter
    margin-bottom: 26px
