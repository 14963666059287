.funds
    height: 100%
    &_titles
        padding: 0 10px
        display: grid
        grid-template-columns: 1fr 4fr 1fr
        margin-bottom: 10px
    &_top
        display: flex
        justify-content: space-between
        margin-bottom: 20px
    h3
        margin: 0
.fund
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
    border-radius: 14px
    padding: 15px
    display: grid
    grid-template-columns: 1fr 4fr 0.5fr 0.5fr
    align-items: center
    .delete
        padding: 0
        background: transparent
        border: none
        display: flex
        justify-content: center
        &:hover
            circle
                fill: #fff

            path
                fill: #546EDB
    a:hover
        svg path
            fill: #546EDB

    img
        width: 53px
        height: 53px
        object-fit: cover
        border-radius: 10px
.pagination
    margin-top: -40px
