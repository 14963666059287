.faq
    &__top_wrapper
        margin-bottom: 35px
        display: flex
        align-items: center
        justify-content: space-between
        gap: 40px
        &_right
            display: flex
            gap: 20px
        h3
            margin-bottom: 0
    &__bottom_wrapper
        display: flex
        gap: 20px
        flex-wrap: wrap
        margin-bottom: 35px

.tab
    display: flex
    gap: 5px
    align-items: center
    padding: 15px
    background: #F7F7FE
    border-radius: 14px
    cursor: pointer

    &.active
        background: #546EDB
        color: #fff
    button
        padding: 0
        display: flex
        background: none
        border: none
        transition: scale .3s ease-in-out
        svg path
            fill: #fff
        &:hover
            scale: 1.3

    &_add
        background: #fff
        border: 1px solid #546EDB
        font-weight: 700
        font-size: 16px
        line-height: 20px
        color: #546EDB
        transition: color .3s ease-in-out, background .3s ease-in-out

        &:hover
            color: #fff
            background: #546EDB
            svg path
                stroke: #fff
.new_section
    display: flex
    align-items: center
    padding: 10px
    gap: 5px
    background: #fff
    border: 1px solid #546EDB
    cursor: pointer
    font-weight: 700
    font-size: 16px
    line-height: 20px
    color: #546EDB
    border-radius: 10px
    transition: color .3s ease-in-out, background .3s ease-in-out

    &:hover
        color: #fff
        background: #546EDB
        svg path
            stroke: #fff

.section
    &_title
        margin-bottom: 20px
        display: flex
        align-items: center
        gap: 8px
        font-size: 18px
        font-weight: 700
        svg
            cursor: pointer
    &_titles
        padding: 0 20px
        display: grid
        grid-template-columns: 27% 70% 3%
        margin-bottom: 20px
        gap: 10px
        font-weight: 800
        font-size: 16px
        line-height: 20px
        color: #C4C4C4
    &_content
        display: grid
        grid-template-columns: 27% 70% 3%
        gap: 10px
        padding: 20px
        background: #FFFFFF
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1)
        border-radius: 14px
        margin-bottom: 10px

.create
    .top_wrapper
        display: flex
        justify-content: space-between
        margin-bottom: 20px
    .title
        display: flex
        align-items: center
        gap: 5px
        div
            cursor: pointer
        h3
            margin-bottom: 0
    .bottom_wrapper
        max-width: 300px
        margin-bottom: 40px
    .question
        margin-bottom: 30px

.btn_red
    width: 150px
    height: 40px
    background: red
    margin-top: 4px
    border-radius: 10px
    border: 0
    display: flex
    align-items: center
    justify-content: center
    font-family: "Gilroy", sans-serif
    font-weight: 700
    font-size: 14px
    line-height: 17px
    color: #ffffff
    transition: all 0.3s ease
    &:hover
        background: #fff
        border: 1px solid red
        color: red

.deleted_span
    color: red
    font-size: 14px
    text-align: right