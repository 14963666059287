.price_order
  width: 254px
  height: 67px
  background: rgba(84, 110, 219, 0.05)
  border-radius: 6px
  display: flex
  justify-content: center
  align-items: center
  font-weight: 800
  font-size: 32px
  line-height: 39px
  color: #546EDB
  text-align: center
  margin: 0 auto 28px

.price_add_to_wallet
  width: 100%
  height: 60px
  border-radius: 5px
  background: #546edb
  display: flex
  justify-content: center
  align-items: center
  color: #fff
  font-weight: 700
  font-size: 16px
  line-height: 20px
  text-align: center
  cursor: pointer
  transition: all 0.4s

.span_points
  font-weight: 400
  font-size: 16px
  line-height: 19px
  text-align: center
  color: #A3AAA6
  margin-bottom: 2px
  transition: all 0.4s

.greenActive .span_points
  color: #07B256
  transition: all 0.4s

.greenActive
  p
    transition: all 0.4s
    color: #07B256 !important
