@import "../../styles/variables"

.card
    background: $white
    position: relative
    height: 100%
    width: 100%
    display: flex
    flex-direction: column
    border: 1px solid rgba(84, 110, 219, 0.15)
    border-radius: 8px
    max-width: 280px
    .share
        position: absolute
        top: 10px
        left: 10px
        display: flex
        justify-content: center
        align-items: center
    &__rating
        width: 52px
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        align-items: center
        justify-content: center
        padding: 5px
        gap: 3px
        position: absolute
        right: 0
        font-weight: 600
        font-size: 12px
        line-height: 14px
        color: $orange
        background: rgba(255, 255, 255, 0.9)
        box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.15)
        border-radius: 0 5px 0 10px
        z-index: 1
        span
            color: #B0A9A9

    &__like
        width: 22px
        height: 22px
        background: rgba(255, 255, 255, 0.9)
        box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.15)
        border: none
        border-radius: 100%
        display: flex
        align-items: center
        justify-content: center
        position: absolute
        top: 28px
        right: 5px
        z-index: 1
        &.active path
            fill: $red

.character
    margin-bottom: 5px
    font-weight: 700
    font-size: 13px
    line-height: 15px
    color: #414253

.descr
    height: 68px
    overflow: hidden
    margin-bottom: 17px
    font-size: 10px
    color: $grey

.fixation
    position: absolute
    top: 5px
    left: 5px
    background: rgba(255, 255, 255, 0.9)
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.15)
    width: 22px
    height: 22px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 100px

.btn
    padding: 16px
    text-align: center
    background: $blue
    border-radius: 5px
    color: $white
    font-weight: 700
    font-size: 15px
    line-height: 19px
    width: 100%
    display: block

.img_wrapper
    display: flex
    border-radius: 8px
    height: 100%
    max-height: 160px
    position: relative
    img
        width: 100%
        object-fit: cover
        border-radius: 8px

.content
    padding: 12px
    display: flex
    flex-direction: column
    height: 100%
    .adv__radios
        display: flex
        width: 100%
        margin-bottom: 10px
        h5
            margin-bottom: 0
            font-weight: 700
            font-size: 13px
            line-height: 16px
        span
            font-weight: 400
            font-size: 10px
            line-height: 12px
        div
            width: 100%
            border: 1px solid #E5E5E5
            border-left: none
            &:first-child
                border-radius: 5px 0 0 5px
                background-color: #4088DD
                color: #fff
                display: flex
                align-items: center
                justify-content: center
            &:last-child
                border-radius: 0 5px 5px 0
                color: #2AA020
                padding: 5px 10px

.title_box
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin-bottom: 12px
    min-height: 34px

.title
    margin: 0
    color: #414253
    font-weight: 700
    font-size: 14px
    line-height: 17px

.status
    padding: 4px 8px 4px 20px
    border-radius: 40px
    font-weight: 500
    font-size: 11px
    line-height: 13px
    color: $white
    background: url('../../assets/images/icons/clock.svg') no-repeat left 5px center

    &.busy
        background-color: $red

    &.soon
        background-color: #FF9F00

    &.free
        background-color: $salad

.prices
    display: flex
    margin-bottom: 15px
    &__left
        label
            border-radius: 5px 0 0 5px

    &__right
        label
            border-radius: 0 5px 5px 0

    &__left, &__right
        width: 50%
        color: $blue
        input:checked + label
            background: $blue
            border: 1px solid $blue
            color: $white
        h5
            margin-bottom: 0
            font-weight: 700
            font-size: 13px
            line-height: 16px
        span
            font-size: 10px
            line-height: 12px
        input
            display: none
        label
            border: 1px solid #E5E5E5
            display: block
            padding: 6px 10px
            cursor: pointer

.adv__info_catalog
    display: flex
    border-radius: 5px
    border: 1px solid #E5E5E5
    margin-bottom: 16px
    height: 50px
    div
        width: 100%
        padding: 2px 2px 2px 8px
        span
            display: block
            font-family: Roboto, sans-serif
            font-size: 10px
    div:first-child
        background: #6FE1B8
        border-radius: 5px
        display: flex
        justify-content: center
        align-items: center
        color: #546EDB
        font-size: 13px
        font-weight: 700

.adv_link
    height: 50px
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    background: #6FE1B8
    font-size: 15px
    color: #546edb
    font-weight: 700
    border-radius: 5px

.organization_checked
    position: absolute
    right: 0
    bottom: 0
    padding: 6px 8px
    border-radius: 8px 0 10px 0
    background: rgba(255, 255, 255, 0.90)
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15) inset
    z-index: 1

.organization_flex
    display: flex
    align-items: center

.organization_text
    font-family: Roboto, sans-serif
    font-size: 11px
    margin-left: 4px
    color: #000

.abs
    position: absolute
    z-index: 2
    display: flex
    justify-content: center
    width: 100%
    align-items: flex-end
    height: 100%
    span
        display: flex
        align-items: center
        padding: 4px 12px
        border-radius: 14px 14px 0 0
        background: rgba(255, 255, 255, 0.85)
        box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05) inset
        backdrop-filter: blur(4.449999809265137px)
        font-family: Roboto, sans-serif
        font-size: 10px
        color: #4088DD
        svg
            zoom: 1.4
            margin-right: 2px

@media screen and (max-width: 576px)
    .card
        max-width: 100%
    .img_wrapper
        img
            max-height: 165px
