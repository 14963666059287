h2 {
    margin-bottom: 13px;
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
}

.green, .blue{
    .img_abs{
        position: absolute;
        bottom: -15px;
        left: 18px;
    }
}
.blue{
    .img_abs{
        bottom: -14px;
    }
}

.advantages{
    margin-bottom: 20px;
}

.button_registration{
    margin: 26px auto 0;
    border-radius: 5px;
    background: #546EDB;
    color: #FFF;
    font-size: 18px;
    border: 1px solid #546edb;
    width: 220px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;
    &:hover{
        color: #546edb;
        background: #fff;
    }
}

.hero_block_abs{
    max-width: fit-content;
    height: 38px;
    position: absolute;
    border-radius: 10px;
    &.green{
        background: #6FE1B8;
        .hero_block_text{
            color: #546EDB;
        }
    }
    &.blue{
        background: #546EDB;
        .hero_block_text{
            color: #fff;
        }
    }
}

.hero_block_flex{
    display: flex;
    align-items: center;
    position: absolute;
    top: 6px;
    left: 8px;
}

.hero_block_text{
    font-family: Roboto, sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 8px 15px 6px 8px;
    svg{
        margin-right: 8px;
    }
}

.hero {
    padding-bottom: 46px;
    background-color: #fff;

    &__bg {
        padding: 54px 0 168px;
        color: #000;
        background-image: url(../../assets/images/index/banner.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        text-align: center;
        border-radius: 14px;
        position: relative;
        p {
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
        }
        h1,
        p {
            max-width: 900px;
            margin: 0 auto;
        }
        p{
            max-width: 660px;
            span{
                color: #546EDB;
            }
        }
        h1 {
            margin-bottom: 12px;
            font-weight: 800;
            font-size: 45px;
            line-height: 110%;
        }
    }
    &__categories {
        ul {
            margin-top: -80px;
            padding: 20px 50px;
            display: flex;
            justify-content: space-between;
            background: #ffffff;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
            border-radius: 15px;
            li{
                width: 33%;
            }
        }
        a {
            display: flex;
            align-items: center;
            gap: 10px;
            color: inherit;
            img {
                width: 163px;
                height: 127px;
                object-fit: contain;
            }
        }
        h3 {
            margin-bottom: 3px;
            font-size: 24px;
            line-height: 29px;
            font-weight: 400;
        }
        button {
            padding: 7px 25px;
            background: #4088dd;
            border-radius: 100px;
            border: none;
            color: #fff;
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.advantages {
    .flex{
        display: flex;
    }
    .flex_first{
        min-width: 380px;
        max-width: 380px;
        margin-right: 34px;
    }
    .swiper_slide{
        border-radius: 8px;
        border: 1px solid #BFCAF5;
        padding: 19px 20px 16px 19px;
        position: relative;
        width: auto;
        flex-shrink: 0;
        display: block;
        height: 100%;
        max-height: 100%;
        max-width: 195px;
    }
    .swiper-wrapper{
        max-height: 100%;
        height: 100%;
        display: flex;
    }
    .check{
        position: absolute;
        top: 18px;
        right: 20px;
    }
    .img{
        margin-bottom: 12px;
    }
    .p{
        font-family: Roboto, sans-serif;
        font-size: 15px;
    }
    .text{
        font-size: 17px;
        color: #000;
        margin-bottom: 12px;
    }
    .adv_drag{
        border-radius: 100px;
        background: #ECECFF;
        width: 166px;
        height: 38px;
        display: flex;
        align-items: center;
        span{
            font-size: 14px;
            color: #A5B9D1;
            margin-left: 8px;
        }
    }
    .adv_drag_img{
        border-radius: 85px;
        background: #F7F7FE;
        width: 48px;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.app {
    padding: 50px 0;
    background: #fff;
    .phone {
        height: auto;
    }
    &__text {
        margin-right: 20px;
        p {
            margin-bottom: 15px;
            font-weight: 400;
            font-size: 20px;
            line-height: 125%;
        }
    }
    h6 {
        margin-bottom: 12px;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 24px;
        line-height: 125%;
    }
    &__wrapper,
    &__links {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: flex-start;
    }
    .special {
        margin-bottom: 15px;
        font-family: Roboto, sans-serif;
        font-weight: 600;
        font-size: 24px;
        line-height: 125%;
        color: #546edb;
    }
}

.app_block{
    border-radius: 8px;
    border: 1px solid #BFCAF5;
    background: #FFF;
    padding: 24px 24px 28px;
    max-width: 280px;
}

.app_qr{
    background: #ECECFF;
    padding: 16px 16px 8px;
    border-radius: 8px;
}

.qr_text{
    font-size: 18px;
    color: #546EDB;
    text-align: center;
    margin: 12px 0;
    font-weight: 500;
}

.app__links{
    justify-content: center;
}

.supply {
    padding: 50px 0;
}

@media (max-width: 1750px) {
    .hero_block_abs{
        zoom: 0.8;
    }
}

@media (max-width: 1200px) {
    .hero {
        &__categories {
            a {
                flex-direction: column;
            }
        }
    }
    .advantages {
        li p {
            font-size: 14px;
        }
    }
    .app {
        &__wrapper {
            position: relative;
        }
        .phone {
            position: absolute;
            right: -280px;
            bottom: 0;
        }
    }
}

@media (max-width: 768px) {
    h2 {
        font-size: 30px;
        line-height: 110%;
    }
    .hero {
        padding-bottom: 35px;
        &__bg {
            padding: 26px 0 128px;
            background-position: left;
            h1 {
                font-weight: 800;
                font-size: 24px;
                line-height: 110%;
            }
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
            }
        }
        &__categories {
            ul {
                gap: 10px;
                margin-top: -110px;
                padding: 14px 20px;
                flex-direction: column;
            }
            a {
                flex-direction: row;
                img {
                    max-width: 40%;
                }
            }
            h3 {
                font-size: 18px;
                line-height: 22px;
            }
            button {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
    .brigades {
        padding-bottom: 35px;
        h2 {
            margin-bottom: 15px;
        }
        &__box {
            flex-direction: column;
        }
    }
    .advantages {
        padding: 25px 0;
        ul {
            flex-wrap: wrap;
        }
        li {
            width: calc(50% - 10px);
        }
        .text {
            font-size: 14px;
            line-height: 17px;
        }
    }
    .app {
        .container {
            max-width: 100%;
        }
        a {
            gap: 5px;
            font-size: 11px;
            line-height: 115%;
        }
        span {
            font-size: 15px;
            line-height: 115%;
        }
        .phone {
            width: 300px;
            right: -158px;
        }
    }
    .flex{
        flex-wrap: wrap;
    }
    .advantages{
        .flex_first{
            margin-bottom: 20px;
        }
    }
    .app__wrapper{
        flex-wrap: wrap;
    }
    .app__text{
        margin-right: 0;
    }
}

@media (max-width: 440px) {
    .hero__bg{
        background-image: url(../../assets/images/index/banner-mob.png);
        padding: 26px 0 320px;
    }
    .app_block{
        max-width: inherit;
    }
}
@media (max-width: 320px) {
    .hero__bg{
        padding: 26px 0 250px;
    }
}