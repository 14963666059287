.ref
    height: 100%
    background: #FFFFFF
    border: 1px solid #DDE2F8
    border-radius: 8px 8px 14px 14px
    position: relative
.top
    display: flex
    &__left
        padding: 20px
        border-right: 1px solid #DDE2F8
    &__right
        padding: 20px
    &__info
        display: flex
        gap: 10px
        margin-bottom: 15px
        flex-wrap: wrap
        &_card
            width: 170px
            padding: 10px 15px
            background: #F6F7FE
            border-radius: 8px
            font-weight: 500
            font-size: 12px
            line-height: 15px
            span
                color: #546EDB
                font-weight: 600
                font-size: 28px
                line-height: 34px
            .points
                color: #3B9A79
    &__text
        font-weight: 500
        font-size: 14px
        line-height: 17px
        span
            color: #FF0000

.points_money
    font-size: 12px
    font-weight: 500
    line-height: 14.56px
    color: #546EDB

.bottom
    padding: 20px
    border-top: 1px solid #DDE2F8
    border-radius: 8px 8px
    &__table
        border: 1px solid rgba(84, 110, 219, 0.2)
        border-radius: 10px
    &__titles
        background: #F6F7FE
        border-bottom: 1px solid rgba(84, 110, 219, 0.2)
        border-radius: 10px
        display: grid
        grid-template-columns: 0.5fr 1fr 1fr 0.5fr 1fr 0.5fr
        padding: 15px
        font-weight: 500
        font-size: 15px
        line-height: 18px
    &_p__titles
        grid-template-columns: 0.5fr 1fr 1fr 0.5fr 1fr 0.5fr 0.5fr
    &__card
        padding: 15px
        display: grid
        grid-template-columns: 0.5fr 1fr 1fr 0.5fr 1fr 0.5fr
        align-items: center
        p
            font-size: 14px
        &_empty
            padding: 15px

        &_null
            font-weight: 500
            font-size: 14px
            line-height: 17px
            color: #FF0000

        &_points
            font-weight: 500
            font-size: 14px
            line-height: 17px
            color: #3B9A79
        img
            width: 30px
            height: 30px
            object-fit: cover
            border-radius: 100%

.flex
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 20px
    flex-wrap: wrap

.bottom_p__table
    .bottom__card
        grid-template-columns: 0.5fr 1fr 1fr 0.5fr 1fr 0.5fr 0.5fr

@media(max-width: 1024px)
    .bottom
        padding: 10px

        &__table
            padding: 10px
        &__mobile_card
            display: flex
            border: 1px solid rgba(84, 110, 219, 0.2)
            border-radius: 8px
            &:not(:last-child)
                margin-bottom: 10px

        &__titles
            flex-direction: column
            display: flex
            gap: 10px
            width: 50%
            border-right: 1px solid #DDE2F8
            border-bottom: none
            font-weight: 500
            font-size: 13px
            line-height: 17px

        &__card
            display: flex
            flex-direction: column
            gap: 10px
            align-items: self-start
            font-weight: 400
            font-size: 13px
            line-height: 16px
            img
                width: 26px
                height: 26px

@media(max-width: 576px)
    .top
        flex-direction: column
        &__left
            border: none
            padding: 10px
        &__right
            padding: 10px
        &__info
            margin-bottom: 10px
            &_card
                padding: 10px
                font-size: 10px
                line-height: 12px
        &__text
            font-size: 12px
            line-height: 15px
