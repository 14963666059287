@font-face
  font-family: 'Gilroy'
  src: local('Gilroy'), url(../assets/fonts/Gilroy-Light.ttf) format('truetype')
  font-weight: 300
  font-display: swap
@font-face
  font-family: 'Gilroy'
  src: local('Gilroy'), url(../assets/fonts/Gilroy-Regular.ttf) format('truetype')
  font-weight: 400
  font-display: swap
@font-face
  font-family: 'Gilroy'
  src: local('Gilroy'), url(../assets/fonts/Gilroy-Medium.ttf) format('truetype')
  font-weight: 500
  font-display: swap
@font-face
  font-family: 'Gilroy'
  src: local('Gilroy'), url(../assets/fonts/Gilroy-Semibold.ttf) format('truetype')
  font-weight: 600
  font-display: swap
@font-face
  font-family: 'Gilroy'
  src: local('Gilroy'), url(../assets/fonts/Gilroy-Bold.ttf) format('truetype')
  font-weight: 700
  font-display: swap
@font-face
  font-family: 'Gilroy'
  src: local('Gilroy'), url(../assets/fonts/Gilroy-Extrabold.ttf) format('truetype')
  font-weight: 800
  font-display: swap

@font-face
  font-family: 'Roboto'
  src: local('Roboto'), url(../assets/fonts/Roboto-Light.ttf) format('truetype')
  font-weight: 300
  font-display: swap
@font-face
  font-family: 'Roboto'
  src: local('Roboto'), url(../assets/fonts/Roboto-Regular.ttf) format('truetype')
  font-weight: 400
  font-display: swap
@font-face
  font-family: 'Roboto'
  src: local('Roboto'), url(../assets/fonts/Roboto-Medium.ttf) format('truetype')
  font-weight: 500
  font-display: swap
@font-face
  font-family: 'Roboto'
  src: local('Roboto'), url(../assets/fonts/Roboto-Bold.ttf) format('truetype')
  font-weight: 600
  font-display: swap
@font-face
  font-family: 'Roboto'
  src: local('Roboto'), url(../assets/fonts/Roboto-Black.ttf) format('truetype')
  font-weight: 700
  font-display: swap
