.head 
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 30px

    h3 
        margin-bottom: 0

.code
    background: #FFFFFF
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
    border-radius: 14px
    margin-bottom: 20px
    padding: 10px
    display: flex
    justify-content: space-between

.delete
    background: transparent
    border: none
    display: flex
    align-items: center
    justify-content: center

    path, circle
        transition: all 0.3s easy-in-out
    &:hover path 
        fill: rgb(84, 110, 219)

    &:hover circle
        fill: #fff

.inputList 
    position: relative
    margin-bottom: 5px
    span 
        position: absolute
        font-size: 12px
        bottom: 0
        left: 0
        color: red

.time_promocode
    margin-bottom: -20px

.error
    outline: 1px solid red