
.tabs
    background: #F6F7FE
    display: flex
    &.square
        border-radius: 10px
        .tab
            border-radius: 8px

    &.circle
        border: 1px solid #6FE1B8
        border-radius: 45px
        .tab
            border-radius: 45px

.tab
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    gap: 5px
    padding: 15px 5px
    font-weight: 600
    font-size: 15px
    line-height: 19px
    cursor: pointer
    color: #546EDB
    .icon
        display: flex
        align-items: center
        justify-content: center
        border-radius: 100%
        background: #546EDB
        width: 20px
        height: 20px
    &.active
        background: #6FE1B8

@media screen and (max-width: 1024px)

    .tabs
        background: #fff
        border: 1px solid #dde2f8
