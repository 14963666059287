.card
    display: flex
    flex-direction: column
    justify-content: space-between
    gap: 20px
    padding: 10px
    background: #ffffff
    border: 2px solid rgba(84, 110, 219, 0.45)
    border-radius: 14px
    width: 100%
    height: 100%
    font-family: "Roboto", sans-serif
    li:not(:last-child)
        margin-bottom: 20px
    &.active
        background: rgba(111, 225, 184, 0.1)
        border: 2px solid #6FE1B8
    &__category_wrapper
        min-width: 239px

    &__with_photo
        padding: 0
        width: 337px

        .btns
            padding: 10px

    h5
        margin-bottom: 10px
        font-weight: 700
        font-size: 16px
        line-height: 19px
        color: #414253

    &__about
        font-size: 14px

    &__category
        font-size: 14px

        p:last-child
            color: rgba(84, 110, 219, 1)

        p:not(:last-child)
            margin-bottom: 15px
    &__selects
        display: flex
        flex-direction: column
        gap: 10px

.cards_wrapper
    display: flex
    gap: 25px
    padding: 0 15px
    position: relative
    &_empty
        text-align: center
        height: 100%
        display: flex
        align-items: center
        justify-content: center
.counter
    display: flex
    justify-content: space-between
    background-color: #546edb
    border-radius: 14px
    background-image: url(../../../../assets/images/moderator/megaphone.png)
    background-repeat: no-repeat
    background-position: center right 240px

    h4
        margin: 0
        font-weight: 700
        font-size: 24px
        line-height: 135%

    &__queue
        padding: 20px 50px
        &_count
            font-weight: 500
            font-size: 32px
            line-height: 135%
            color: #ffffff

        h4
            color: #d4dbf6

    &__created
        padding: 20px 50px
        background: #6fe1b8
        border-radius: 14px 14px 14px 55px
        h4
            color: #546edb

    &__tabs
        background: #A7B5F2
        display: flex
        justify-content: space-between
        gap: 20px
        border-radius: 50px
        padding: 6px 10px
        position: relative
        font-weight: 400
        font-size: 13px
        line-height: 135%
        color: #FFFFFF

        &::after
            content: ""
            position: absolute
            width: 45%
            height: 100%
            background: #546EDB
            border-radius: 50px
            left: 0
            top: 0
        span:nth-child(1)
            font-weight: 700
            font-size: 15px
            z-index: 10
            position: relative

.img_relative
    position: relative

.decline
    position: absolute
    right: 12px
    top: 10px
    z-index: 2
    color: white
    cursor: pointer
    font-size: 20px
    width: 30px
    height: 30px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 100%
    background: #546edb
    transition: all 0.4s
    &:hover
        color: red

.card__link
    font-family: Roboto, sans-serif
    color: #000
    font-size: 14px
    text-decoration-line: underline

.card__organization
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    padding-bottom: 40px
    max-width: 570px
    p
        margin-bottom: 12px

.card_org
    gap: 0

@media screen and (max-width: 1200px)
    .cards_wrapper
        gap: 10px
        position: static

@media screen and (max-width: 768px)
    .cards_wrapper
        flex-direction: column
    .card
        width: 100%

        &__with_photo
            width: 100%
    .counter
        margin-top: -82px
        flex-direction: column
        background-color: #6FE1B8
        border-radius: 14px 14px 55px 14px
        background-image: none

        &__queue
            background-color: #546edb
            border-radius: 10px
        &__created
            background: none

@media screen and (max-width: 576px)
    .counter
        &__tabs
            width: 185px
        h4
            font-size: 20px
        &__queue
            padding: 8px 15px
            &_count
                font-size: 27px
        &__created
            padding: 15px
