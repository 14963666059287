.categories
    display: flex
    justify-content: space-between
    margin-bottom: 40px
    &_wrapper
        display: flex
        gap: 10px
        flex-wrap: wrap
.category
    display: flex
    gap: 5px
    align-items: center
    padding: 15px
    background: #F7F7FE
    border-radius: 14px
    button
        padding: 0
        display: flex
        align-items: center
        justify-content: center
        background: transparent
        border: none
        &:hover
            svg path
                fill: #546EDB

    &_add
        display: flex
        padding: 15px
        gap: 5px
        border-radius: 14px
        border: 1px solid #546EDB
        cursor: pointer
        font-weight: 700
        font-size: 16px
        line-height: 20px
        color: #546EDB
        transition: color .3s ease-in-out, background .3s ease-in-out
        &:hover
            color: #fff
            background: #546EDB
            svg path
                stroke: #fff
.articles

    &_titles
        padding: 0 10px
        display: grid
        grid-template-columns: 1fr 4fr 2fr 1fr
        margin-bottom: 10px
    .delete
        padding: 0
        background: transparent
        border: none
        display: flex
        justify-content: center
        &:hover
            circle
                fill: #fff

            path
                fill: #546EDB
.article
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
    border-radius: 14px
    padding: 15px
    display: grid
    grid-template-columns: 1fr 4fr 2fr 0.5fr 0.5fr
    align-items: center
    a:hover
        svg path
            fill: #546EDB
    img
        width: 53px
        height: 53px
        border-radius: 10px
        object-fit: cover
