.card
    max-height: 500px
    height: 100%
    width: 100%
    max-width: 280px
    background-color: #fff
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1)
    border-radius: 15px
    &__info
        padding: 20px 35px
        text-align: center
        img
            margin-bottom: 5px
            width: 130px
            height: 130px
            object-fit: cover
            border-radius: 100%
        &_user

            display: flex
            flex-direction: column
            gap: 5px
    .role
        display: block
        max-width: 210px
        margin: 0 auto
        padding: 10px
        background: #6fe1b8
        border-radius: 50px
        font-weight: 500
        font-size: 15px
        line-height: 135%
        text-align: center
        color: #ffffff
        font-family: "Roboto"

    &__balance
        background: #546edb
        border-radius: 15px
        color: rgba(212, 219, 246, 1)
        &_top
            padding: 20px 35px 10px
            background: url(../../../assets/images/moderator/balance.png) no-repeat right

        &_bottom
            padding: 20px 35px
            border-top: 1px solid #6fe1b8
            border-radius: 15px
            font-family: "Roboto"
            p
                font-weight: 600
                font-size: 12px
                line-height: 135%
                position: relative
                &::before
                    content: ""
                    position: absolute
                    width: 8px
                    height: 8px
                    top: 3px
                    left: -13px
                    background: #6fe1b8
                    border-radius: 100%

            span
                font-weight: 400
                color: #ffffff

        &_title
            font-weight: 700
            font-size: 24px
            line-height: 135%

        &_summ
            font-weight: 500
            font-size: 32px
            line-height: 135%
            color: #ffffff
            font-family: "Roboto"

@media screen and (max-width: 1024px)
    .card
        max-width: unset
        box-shadow: none
        .role
            padding: 6px 15px
            font-size: 14px
        &__info
            padding: 15px
            display: flex
            gap: 20px
            img
                width: 80px
                height: 80px
            &_user
                text-align: left
