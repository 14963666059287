.container
    display: flex
    gap: 20px
    position: relative
    &__empty
        margin: auto
.account_card
    width: 100%
    align-items: center
    img
        width: 200px
.btns
    width: 100%
