.status_organization
    position: absolute
    left: 0
    bottom: -6px

.star
    opacity: 0.4

.adv
    padding: 40px 0

    &__wrapper
        display: flex
        gap: 20px
        justify-content: space-between

    &__photo
        position: relative
        display: flex
        .rating
            position: absolute
            right: 0
            top: 0
            align-items: center
            background: hsla(0,0%,100%,.9)
            box-shadow: inset 0 4px 4px rgb(0 0 0 / 15%)
            display: flex
            justify-content: center
            border-radius: 0 5px 0 10px
            color: #ffa800
            font-size: 12px
            font-weight: 600
            gap: 3px
            line-height: 14px
            padding: 5px
            width: 52px
            span
                color: #B0A9A9

        .like
            display: flex
            align-items: center
            position: absolute
            border: none
            border-radius: 100%
            height: 22px
            right: 5px
            top: 28px
            width: 22px
            &.active path
                fill: #E21111
        img
            max-height: 320px
            width: 100%
            object-fit: cover
            border-radius: 8px

    &__descr
        width: calc(25% - 20px)
        h3
            margin-bottom: 23px
            font-weight: 700
            font-size: 27px
            line-height: 33px
            word-wrap: break-word
        h5
            margin-bottom: 10px
            font-weight: 700
            font-size: 16px
            line-height: 19px
            color: #414253
        p
            font-size: 15px
            line-height: 125%
            color: #858585
            max-height: 170px
            overflow: auto

    &__user
        width: calc(35% - 10px)
        padding: 23px 26px
        background: #FFFFFF
        border: 1px solid #F3F4F9
        border-radius: 8px
        display: flex
        flex-direction: column
        gap: 15px
        height: 320px
        &_wrapper
            display: flex
            gap: 10px
        &_share
            &:hover
                cursor: pointer
                path
                    stroke: #4088DD
        .info
            display: flex
            width: 100%
            justify-content: space-between
            gap: 10px
            color: inherit
            position: relative
            h4
                margin-bottom: 6px
                font-weight: 700
                font-size: 20px
                line-height: 23px
            span
                font-weight: 500
                font-size: 14px
                line-height: 16px
                color: #FFFFFF

        img
            width: 100%
            max-width: 60px
            height: 60px
            border-radius: 100%
            object-fit: cover

        .status
            display: inline
            padding: 4px 8px 4px 20px
            border-radius: 40px
            font-weight: 500
            font-size: 11px
            line-height: 13px
            color: #FFFFFF
            background-position: left 5px center
            background-repeat: no-repeat
            &_wrapper
                width: 100%

            &.busy
                background-color: #E21111
                background-image: url('../../../assets/images/icons/clock.svg')

            &.soon
                background-color: #FF9F00
                background-image: url('../../../assets/images/icons/clock.svg')
            &.free
                background-color: #6FE1B8
                background-image: url('../../../assets/images/icons/clock.svg')
            &.search
                background-color: #6FE1B8
                background-image: url('../../../assets/images/icons/searchh.svg')
                padding-left: 28px

        button
            padding: 16px
            font-weight: 700
            font-size: 15px
            line-height: 19px
            text-align: center
            color: #FFFFFF
            border: none
            background: #546EDB
            border-radius: 5px
            width: 100%
            &.contacts
                margin-bottom: 11px
                background: #6FE1B8

    &__radios
        display: flex
        border: 1px solid #E5E5E5
        border-radius: 6px
        color: #546EDB
        overflow: hidden
        .search
            display: flex
            width: 100%
            div
                width: 100%
                &:first-child
                    background-color: #4088DD
                    color: #fff
                    display: flex
                    align-items: center
                    justify-content: center
                &:last-child
                    color: #2AA020
                    padding: 5px 10px

        h5
            margin-bottom: 0
            font-weight: 700
            font-size: 17px
            line-height: 21px
        span
            font-size: 13px
            line-height: 16px
        label
            padding: 7px 10px
            width: 50%
            position: relative
            transition: all .3s ease-in-out
            cursor: pointer
            &::after
                content: ""
                width: 15px
                height: 15px
                position: absolute
                top: 10px
                right: 12px
                background: url('../../../assets/images/catalog/check-blue.svg') no-repeat center
                transition: all .3s ease-in-out
        input
            display: none
            &:checked + label
                background: #4088DD
                color: #fff
                &::after
                    background: url('../../../assets/images/catalog/check-white.svg') no-repeat center

.feedback
    padding-bottom: 40px
    h2
        margin-bottom: 17px
        font-weight: 700
        font-size: 30px
        line-height: 37px

    &__cards_wrapper
        display: flex
        gap: 20px
    &__card
        min-width: 280px
        max-width: 280px
        padding: 20px
        background: #FFFFFF
        border-radius: 8px
    .user
        display: flex
        gap: 16px
        margin-bottom: 20px
        .name
            font-weight: 700
            font-size: 16px
            line-height: 19px
            color: #414253
        .date
            font-size: 14px
            line-height: 16px
            color: #BEBEBE

        img
            width: 50px
            height: 50px
            object-fit: cover
            border-radius: 100%
    .stars
        display: flex
        gap: 5px

    &__slider
        max-width: 600px
        position: relative
    .shadow
        &::after
            content: ""
            position: absolute
            right: 0
            top: 0
            height: 100%
            display: block
            width: 120px
            transform: scaleX(-1)
            background: linear-gradient(90deg, #FFFFFF 2.5%, rgba(255, 255, 255, 0) 55.36%)

@media screen and (max-width: 1200px)
    .adv
        h3
            margin-bottom: 15px
            font-size: 18px
            line-height: 23px

@media screen and (max-width: 1024px)
    .adv__user
        padding: 15px
    .search
        h5
            font-size: 15px
            margin-bottom: 10px

@media screen and (max-width: 768px)
    .adv__photo
        display: block
    .adv
        padding: 20px 0
        h3
            margin-bottom: 10px
            font-size: 20px
        h5
            margin-bottom: 5px
        &__descr
            p
                font-size: 14px
        &__wrapper
            flex-direction: column
            gap: 16px
        &__photo, &__descr, &__user
            width: 100%
            .slick_list
                width: 480px

        &__photo
            .rating
                width: 59px
            .like
                height: 25px
                width: 25px
                top: 31px
            img
                max-width: unset

        &__user
            padding: 18px 15px
            button
                padding: 14px
                font-size: 13px
            .status
                max-width: 140px
            .info h4
                font-size: 16px
                line-height: 20px
        &__radios
            h5

                font-size: 15px
            span
                font-size: 11px

    .feedback
        h2
            font-size: 20px
        &__card
            max-width: unset

@media screen and (max-width: 576px)
    .search
        h5
            font-weight: 700
            font-size: 13px
            line-height: 17px
        span
            font-weight: 400
            font-size: 11px
            line-height: 14px
