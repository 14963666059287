.list__container
    display: flex
    gap: 20px
.searching
    padding: 50px 0
    .title
        margin-bottom: 20px
        span
            font-weight: 600
            font-size: 30px
            line-height: 110%
            color: rgba(84, 110, 219, 0.5)
    h2
        margin-bottom: 0
        font-weight: 600
        font-size: 30px
        line-height: 110%

@media screen and (max-width: 1024px)
    .searching
        padding: 25px 0
        h2, .title span
            font-weight: 600
            font-size: 20px
            line-height: 125%

@media screen and (max-width: 440px)
    .list__container
        display: block