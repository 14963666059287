.modal
    max-width: 450px
    h3
        font-weight: 800
        font-size: 36px
        line-height: 110%
        text-align: center
        color: #2EC28D
        margin-bottom: 10px
    img
        margin: 0 auto
        margin-bottom: 15px
    .text
        margin-bottom: 20px
        font-weight: 500
        font-size: 18px
        line-height: 22px
        text-align: center
    .special
        color: #4F68CF
    button
        background: #2EC28D
        border-radius: 10px
        padding: 17px
        border: none
        font-weight: 700
        font-size: 20px
        line-height: 25px
        color: #FFFFFF
        position: relative
        transition: .3s ease-in-out
        &:hover
            background: #2AAE7F

        span
            position: absolute
            top: 0
            right: 0
            height: 100%
            background: #2AAE7F
            border-radius: 10px
            padding: 10px 20px
            display: flex
            align-items: center
    .phone_wrapper
        background-color: #EBEDFF
        border: 1px solid #546EDB
        border-radius: 10px
        padding: 15px
        font-weight: 500
        font-size: 24px
        line-height: 29px
        margin-bottom: 25px
        background-image: url(../../../assets/images/cabinet/tel.svg)
        background-position: right 20px center
        background-repeat: no-repeat
    .descr
        font-weight: 500
        font-size: 18px
        line-height: 22px
        margin-bottom: 10px
    .close
        position: absolute
        right: 20px
        top: 20px
        width: 40px
        height: 40px
        cursor: pointer
        &:hover
            svg path
                fill: #000
        svg
            width: 100%
            height: 100%
            path
                fill: #2EC28D
@media screen and (max-width: 768px)
    .modal
        max-width: 90%
        h3
            font-size: 23px
            line-height: 110%
        .text
            margin-bottom: 10px
            font-size: 12px
            line-height: 14px
        .descr
            font-size: 18px
            line-height: 22px
        .close
            right: 5px
            top: 5px
        img
            max-width: 143px
            height: auto
        button
            padding: 10px
            font-size: 13px
            line-height: 16px
            border-radius: 6px
            span
                padding: 10px
