.header
    padding: 30px 0
    &__container
        display: flex
        align-items: center
        justify-content: space-between

.logout
    gap: 10px
    &:hover
        path
            fill: #546edb

@media screen and (max-width: 1024px)
    .header
        position: relative
    .menu
        background: #fff
        border-radius: 0 0 10px 10px
        z-index: 20
        width: 100%
        left: 0
        &_overlay
            position: fixed
            top: 110px
            right: 0
            bottom: 0
            left: 0
            background: rgba(0,0,0,0.5)
            z-index: 1050

    .logout
        width: 100%
        padding: 15px
        &_wrapper
            padding: 15px
