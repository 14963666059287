.aside__wrapper
    min-width: 280px
    background: #fff
    border-radius: 14px
    display: flex
    flex-direction: column
    border: 1px solid #DDE2F8
    z-index: 35

.user_info
    padding: 15px 0 15px 10px
