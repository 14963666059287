.login
  padding-bottom: 65px

  &__banner
    height: 545px
    background: url('../../assets/images/login.webp') no-repeat bottom
    background-size: cover

  &__block
    max-width: 1530px
    width: 100%
    height: 682px
    background: #FFFFFF
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1)
    border-radius: 40px
    margin: 0 auto
    margin-top: -142px
    padding-top: 42px

    .input input::-webkit-input-placeholder
      color: #414253

    .input input::-moz-placeholder
      color: #414253

    .input input:-ms-input-placeholder
      color: #414253

    .input input::-ms-input-placeholder
      color: #414253

    .input input::placeholder
      color: #414253

    .input .icon
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: 20px

    .admin-error
      opacity: 0
      margin-bottom: -20px
      text-align: center
      color: red
      &.visible
        opacity: 1
    .error
      position: absolute
      bottom: -10px
      left: 3px
      font-size: 12px
      color: red

    .code-error
      margin: 10px 0
      display: block
      font-size: 12px
      color: red

    .links
      display: flex
      justify-content: center
      align-items: center

      .admin-link
        font-family: "Roboto", sans-serif
        font-weight: 800
        font-size: 40px
        line-height: 134.75%
        color: #4088DD
      .link
        font-family: "Roboto", sans-serif
        font-weight: 500
        font-size: 36px
        line-height: 42px
        color: #414253
        margin-right: 46px
        cursor: pointer

        &:last-child
          margin-right: 0

        &.active
          font-weight: 800
          font-size: 40px
          line-height: 134.75%
          color: #4088DD

    .input
      width: 380px
      position: relative
      margin-bottom: 20px

      input
        background: #E8EBFF
        border: 1px solid rgba(84, 110, 219, 0.2)
        border-radius: 10px
        width: 100%
        height: 60px
        padding: 0 20px
        font-family: "Roboto", sans-serif
        font-weight: 400
        font-size: 14px
        line-height: 16px
        color: #414253
        &:focus
          border: 1px solid #546EDB

  &__entrance
    display: flex
    flex-direction: column
    gap: 20px
    max-width: 380px
    width: 100%
    margin: 0 auto
    margin-top: 134px
    margin-bottom: 222px

  &__registration
    max-width: 735px
    width: 100%
    margin: 0 auto
    margin-top: 34px
    margin-bottom: 100px

  &__code
    max-width: 497px
    width: 100%
    margin: 0 auto
    margin-top: 78px
    margin-bottom: 84px

    .title
      font-family: "Roboto", sans-serif
      font-weight: 700
      font-size: 24px
      line-height: 28px
      color: #414253
      margin-bottom: 9px
      text-align: center

    .text
      font-family: "Roboto", sans-serif
      font-weight: 400
      font-size: 18px
      line-height: 21px
      color: #404B55
      text-align: center
      margin-bottom: 26px

    .link_box
      display: flex
      justify-content: center
      margin-top: 18px

    .link
      font-family: "Roboto", sans-serif
      font-weight: 700
      font-size: 14px
      line-height: 16px
      color: #4088DD

    .code_box
      display: flex
      justify-content: center

    .code_input
      width: 70px
      height: 90px
      margin-right: 8px

      input
        width: 100%
        height: 100%
        background: #F6F8F9
        border-radius: 6px
        font-family: "Roboto", sans-serif
        font-weight: 300
        font-size: 40px
        line-height: 47px
        color: #4088DD
        border: 0
        text-align: center

      &:last-child
        margin-right: 0

  &__box
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    max-width: 735px
    flex-direction: column
    align-items: center
    position: relative

  &__btn
    max-width: 380px
    width: 100%
    height: 60px
    background: #546EDB
    border-radius: 14px
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    align-items: center
    justify-content: center
    font-family: "Gilroy", sans-serif
    font-weight: 700
    font-size: 18px
    line-height: 22px
    color: #FFFFFF
    margin: 0 auto
    margin-top: 20px
    transition: all 0.3s ease
    border: 2px solid transparent

    &:hover
      background: rgba(64, 136, 221, 0.07)
      color: #546EDB
      border: 2px solid #546EDB

  .terms
    margin: 0 auto
    max-width: 450px
    font-size: 12px
    text-align: center
    a
      color: #546EDB

.modal_register
  position: fixed
  background: #FFFFFF
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 20
  box-shadow: 0 3.33333px 3.33333px rgba(0, 0, 0, 0.25)
  border-radius: 25px
  text-align: center
  margin: auto
  width: 700px
  padding: 44px 0 66px

.modal_title
  font-family: 'Gilroy', sans-serif
  font-style: normal
  font-weight: 900
  font-size: 28px
  line-height: 29px
  color: #4088DD
  margin-bottom: 40px

.modal_btn
  background: #546EDB
  border-radius: 12px
  width: 226.33px
  height: 50px
  color: #fff
  border: none
  font-weight: 700
  font-size: 15px
  line-height: 18px
  transition: all 0.4s
  &:hover
    opacity: 0.9

.auth-code
  text-align: center
  color: #414253
  font-family: "Roboto"
  h3
    font-weight: 700
    font-size: 24px
    line-height: 28px
  .descr
    margin-bottom: 25px
    font-weight: 400
    font-size: 18px
    line-height: 21px
  .nums-wrapper
    display: flex
    gap: 5px
    margin-bottom: 20px
    justify-content: center
  .get-code
    cursor: pointer
    font-weight: 700
    font-size: 14px
    line-height: 16px
    color: #4088DD

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none
  margin: 0 /* <-- Apparently some margin are still there even though it's hidden */

input[type=number]
  -moz-appearance: textfield /* Firefox */

.required.input input::placeholder
  background: linear-gradient(to right, black 0% 90px, red 80px, black 90% 100%)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

.requiredTel.input input::placeholder
  background: linear-gradient(to right, black 0% 60px, red 1.19em 1.9em, black 80% 100%)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

.requiredEmail.input input::placeholder
  background: linear-gradient(to right, black 0% 38px, red 1.19em 1.9em, black 80% 100%)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent

.icon_relative
  position: relative
  cursor: pointer
  .icon__modal
    position: absolute
    bottom: 26px
    min-width: 140px
    right: -18px
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='140' height='102' fill='none'%3E%3Cmask id='a' fill='%23fff'%3E%3Cpath fill-rule='evenodd' d='M10 0C4.477 0 0 4.477 0 10v63c0 5.523 4.477 10 10 10h82.999a13 13 0 0 1 11.069 6.183l6.729 10.927c.781 1.268 2.625 1.268 3.406 0l7.778-12.631A9.419 9.419 0 0 1 130 83c5.523 0 10-4.477 10-10V10c0-5.523-4.477-10-10-10H10Z' clip-rule='evenodd'/%3E%3C/mask%3E%3Cpath fill='%23fff' fill-opacity='.9' fill-rule='evenodd' d='M10 0C4.477 0 0 4.477 0 10v63c0 5.523 4.477 10 10 10h82.999a13 13 0 0 1 11.069 6.183l6.729 10.927c.781 1.268 2.625 1.268 3.406 0l7.778-12.631A9.419 9.419 0 0 1 130 83c5.523 0 10-4.477 10-10V10c0-5.523-4.477-10-10-10H10Z' clip-rule='evenodd'/%3E%3Cpath fill='%23546EDB' fill-opacity='.5' d='m110.797 100.11.852-.525-.852.525Zm3.406 0 .851.524-.851-.524Zm-10.135-10.927.851-.524-.851.524ZM1 10a9 9 0 0 1 9-9v-2C3.925-1-1 3.925-1 10h2Zm0 63V10h-2v63h2Zm9 9a9 9 0 0 1-9-9h-2c0 6.075 4.925 11 11 11v-2Zm82.999 0H10v2h82.999v-2Zm18.65 17.585-6.73-10.926-1.703 1.048 6.73 10.927 1.703-1.049Zm1.702 0a1 1 0 0 1-1.702 0l-1.703 1.049c1.171 1.903 3.937 1.903 5.108 0l-1.703-1.049Zm7.779-12.63-7.779 12.63 1.703 1.049 7.779-12.63-1.703-1.05ZM139 73a9 9 0 0 1-9 9v2c6.075 0 11-4.925 11-11h-2Zm0-63v63h2V10h-2Zm-9-9a9 9 0 0 1 9 9h2c0-6.075-4.925-11-11-11v2ZM10 1h120v-2H10v2Zm112.833 87.003A8.417 8.417 0 0 1 130 84v-2a10.417 10.417 0 0 0-8.87 4.955l1.703 1.048ZM92.999 84c4.165 0 8.033 2.16 10.217 5.707l1.703-1.048A13.999 13.999 0 0 0 92.999 82v2Z' mask='url(%23a)'/%3E%3C/svg%3E")
    font-style: italic
    font-size: 9px
    line-height: 11px
    padding: 8px 11px 28px 12px
    span
      color: #546EDB

.my-node-enter
  opacity: 0

.my-node-enter-active
  opacity: 1
  transition: opacity 200ms

.my-node-exit
  opacity: 1

.my-node-exit-active
  opacity: 0
  transition: opacity 200ms

@media (max-width: 440px)
  main .login__block
    padding-bottom: 160px !important
