.tabs_wrapper
    margin-left: -5px
    margin-right: 5px
.tabs
    background: #F6F7FE
    border-radius: 10px
    display: flex
    margin-bottom: 10px
.tab
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    gap: 5px
    padding: 15px 5px
    border-radius: 8px
    font-weight: 600
    font-size: 15px
    line-height: 19px
    cursor: pointer
    color: #546EDB
    .icon
        display: flex
        align-items: center
        justify-content: center
        border-radius: 100%
        background: #546EDB
        width: 20px
        height: 20px
    &.active
        background: #6FE1B8
.top_wrapper
    padding: 15px 0 15px 10px
.nav_bottom
    padding: 10px
    padding-right: 0
.toggle
    display: flex
    gap: 5px
    align-items: center
    margin-bottom: 20px
    label
        display: inline-block
        height: 21px
        position: relative
        width: 40px
    input
        height: 0
        opacity: 0
        width: 0
        &:checked+span
            background-color: #546EDB
            &::before
                transform: translateX(19px)

    span
        border-radius: 34px
        background-color: #727272
        bottom: 0
        cursor: pointer
        left: 0
        position: absolute
        right: 0
        top: 0
        transition: transform .4s ease-in-out
        &::before
            background-color: #fff
            bottom: 2px
            content: ""
            height: 17px
            left: 2px
            position: absolute
            transition: .4s
            width: 17px
            border-radius: 50%
@media screen and (max-width: 1024px)
    .tabs_wrapper
        margin: 0
    .tabs
        background: #fff
        border: 1px solid #dde2f8
