.partner_program_banner{
  background: url("./images/banner.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  border-radius: 14px;
  position: relative;
  padding: 54px 0 230px;
  h1{
    font-size: 45px;
    font-weight: 800;
    line-height: 50px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 12px;
  }
  p{
    max-width: 550px;
    text-align: center;
    margin: 0 auto;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0;
  }
  .partner_program_btn{
    margin: 26px auto 0;
    border-radius: 5px;
    background: #546EDB;
    color: #FFF;
    font-size: 18px;
    border: 1px solid #546edb;
    width: 220px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;
    &:hover{
      color: #546edb;
      background: #fff;
    }
  }
}

.partner_program_h2{
  font-size: 40px;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 14px;
}

.partner_program_video{
  padding-top: 64px;
  .partner_program_h2{
    position: relative;
    &:before{
      content: url("./images/videoBlock/line.svg");
      position: absolute;
      left: -38px;
      top: 22px;
    }
  }
  .partner_program_video_flex{
    margin-top: 34px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .program_video_block{
    padding: 20px 26px 24px 18px;
    border: 1px solid #BFCAF5;
    border-radius: 8px;
    margin-bottom: 24px;
  }
  .program_video_title{
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
    position: relative;
    margin-bottom: 14px;
    padding-left: 10px;
    &:before{
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      display: block;
      width: 49px;
      height: 27px;
      background: #EBEFFF;
      border-radius: 50px;
    }
  }
  .program_video_referral{
    background: #ECECFF;
    padding: 8px 23px 8px 64px;
    height: 45px;
    position: relative;
    border-radius: 100px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    max-width: max-content;
  }
  .program_video_img{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 53px;
    position: absolute;
    left: 0;
    top: 0;
    background: #F7F7FE;
    border-radius: 85px;
  }
  .program_video_link{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    color: #546EDB;
  }
  .program_video_descr{
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
    padding-left: 8px;
    max-width: 336px;
  }
}

.partner_program_payment{
  padding-top: 34px;
  .partner_program_payment_p{
    max-width: 740px;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    margin-bottom: 24px;
  }
  .partner_program_block_sub{
    max-width: 167px;
  }
  .partner_program_block_blue{
    background: #546EDB;
    padding: 24px 19px 23px 22px;
    margin-top: 6px;
    border-radius: 15px;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
  }

  .partner_program_payment_flex{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .partner_program_payment_block{
    border: 1px solid #BFCAF5;
    border-radius: 8px;
    padding: 35px 28px 28px 26px;
  }
  .partner_program_payment_topFlex{
    display: flex;
    justify-content: space-between;
  }
  .partner_program_payment_top_right{
    p{
      font-size: 64px;
      font-weight: 250;
      line-height: 77px;
      letter-spacing: 0;
      text-align: right;
      margin-top: -4px;
    }
  }
  .partner_program_block01{
    max-width: 294px;
    .partner_program_number{
      color: rgba(84, 110, 219, 0.2);
    }
  }
  .partner_program_block02{
    max-width: 326px;
    padding-right: 22px;
    .partner_program_number{
      color: rgba(84, 110, 219, 0.45);
    }
  }
  .partner_program_block03{
    max-width: 332px;
    .partner_program_number{
      color: rgba(84, 110, 219, 1);
    }
  }
  .partner_program_payment_title{
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0;
    position: relative;
    margin-top: 28px;
    &:before{
      content: "";
      position: absolute;
      z-index: -1;
      left: -10px;
      display: block;
      width: 49px;
      height: 27px;
      background: #EBEFFF;
      border-radius: 50px;
    }
  }
  .partner_program_payment_description{
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
  }
}

.partner_program_statistic{
  margin-top: 68px;
  margin-bottom: 64px;
}

.partner_program_statistic_flex{
  display: flex;
  justify-content: space-between;
  .partner_program_statistic_text{
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    margin-bottom: 24px;
    max-width: 624px;
  }
  .partner_program_statistic_block{
    border: 1px solid #BFCAF5;
    border-radius: 8px;
    padding: 22px 26px 26px 18px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 480px;
    background: #fff;
  }
  .partner_program_statistic_block1{
    max-width: 380px;
  }
  .partner_program_statistic_block_text{
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0;
  }
  .partner_program_statistic_right{
    position: relative;
    img{
      position: absolute;
      right: 0;
      z-index: -1;
    }
  }
}

@media (max-width: 1337px) {
  .partner_program_banner{
    padding: 54px 0 70px;
  }
}

@media (max-width: 1220px) {
  .partner_program_video .partner_program_h2:before{
    display: none;
  }
  .partner_program_video_left{
    display: flex;
    gap: 10px;
  }
  .partner_program_payment .partner_program_payment_block{
    max-width: 49% !important;
  }
  .partner_program_video .program_video_block{
    width: 100%;
  }
  .partner_program_video .partner_program_video_flex{
    display: block;
  }
}

@media (max-width: 1024px) {
  .partner_program_payment .partner_program_payment_block{
    max-width: 48% !important;
  }
  .partner_program_statistic_flex{
    display: block;
  }
  .partner_program_statistic_flex .partner_program_statistic_right img{
    position: relative;
  }
  .partner_program_statistic_flex .partner_program_statistic_text,
  .partner_program_statistic_flex .partner_program_statistic_block{
    max-width: 100%;
  }
  .partner_program_video_right img{
    width: 100%;
  }
}

@media (max-width: 768px){
  .partner_program_h2,
  .partner_program_banner h1{
    font-size: 24px;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 0;
  }
  .partner_program_banner p{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    max-width: 272px;
  }
  .partner_program_banner .partner_program_btn{
    width: 215px;
    height: 55px;
  }
  .partner_program_statistic_right img{
    width: 100%;
  }
}

@media (max-width: 640px){
  .partner_program_video_left{
    flex-wrap: wrap;
  }
}

@media (max-width: 460px){
  .partner_program_banner{
    background: url("./images/bannerMobile.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 26px 0 300px;
  }
  .partner_program_banner .partner_program_btn{
    margin: 20px auto 0;
  }
  .partner_program_video{
    padding-top: 40px;
  }
  .partner_program_video .partner_program_video_flex{
    margin-top: 0;
  }
  .partner_program_video .program_video_block{
    margin-bottom: 5px;
  }
  .partner_program_video_right{
    margin-top: 10px;
  }
  .partner_program_payment .partner_program_payment_block{
    max-width: 100% !important;
  }
  .partner_program_payment .partner_program_block_sub{
    display: flex;
    gap: 5px;
    max-width: 100%;
    img{
      width: 107px;
      height: 100px;
    }
  }
  .partner_program_payment .partner_program_block_blue{
    margin-top: 0;
    font-size: 13px;
    line-height: 16px;
    padding: 16px;
  }
  .partner_program_statistic{
    margin-top: 40px;
    margin-bottom: 40px;
  }
}