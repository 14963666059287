@import "./styles/fonts"

html
  scroll-behavior: smooth
*
  box-sizing: border-box
  outline: none
  &:focus
    outline: none

.backgr_banner
  background: linear-gradient(270deg, #DEE4FF 0%, rgba(171, 187, 255, 0) 100%)
  display: inline-block
  padding: 0 10px 0 0
  border-radius: 10px

.edit_abbreviation
  margin-top: 12px
  .flex
    justify-content: space-between
  h3
    margin-bottom: 0

.abbreviation_list
  margin-top: 10px

.abbreviation_list_item
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid #dde2f8
  padding: 15px

.abbreviation_list_item_edit
  display: flex
  align-items: center
  gap: 10px

.abbreviation_edit_icon
  cursor: pointer
  &:hover
    svg path
      fill: #546EDB

#root
  overflow-x: hidden

.flex-category
  justify-content: center

.h4_partner_modal
  margin-bottom: 6px
  font-weight: 500

.btnZXC
  border: none
  padding: 14px 22px
  font-weight: 500
  font-size: 18px
  line-height: 22px
  color: #FFFFFF
  background: #546EDB
  border-radius: 14px
  cursor: pointer
  transition: opacity 0.3s ease-in-out
  &:hover
    opacity: 0.8

.hard_green
  color: #3B9A79

.agreement_text_modal
  font-size: 14px
  font-weight: 400
  line-height: 17px
  letter-spacing: 0
  text-align: center
  span
    color: #546EDB

.benefit_flex
  display: flex

.input-file
  position: relative

.input-file span
  position: relative
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  outline: none
  text-decoration: none
  font-size: 17px
  font-weight: 700
  color: #3B9A79
  text-align: center
  border-radius: 10px
  background-color: #fff
  line-height: 22px
  box-sizing: border-box
  border: 1px solid #3B9A79
  margin-left: auto
  width: 170px
  height: 60px
  transition: background-color 0.4s

.input-file input[type=file]
  position: absolute
  z-index: -1
  opacity: 0
  display: block
  width: 0
  height: 0

/* Hover/active */
.input-file:hover span
  background-color: #3B9A79
  color: #FFFFFF

.input-file:active span
  background-color: #3B9A79
  color: #FFFFFF

/* Disabled */
.input-file input[type=file]:disabled + span
  background-color: #eee

.modal.modalOrdered.modal_account_confirmed
  height: 445px
  padding: 36px 28px 42px

.modal.modalOrdered.modal_account_withdraw
  height: 304px

.modal_account_agreement
  width: 450px
  height: 580px
  padding: 36px 40px 34px
  img
    margin: 0 auto

.account_agreement_span
  width: 28px
  height: 28px
  display: flex
  justify-content: center
  align-items: center
  background: #6FE1B8
  color: #FFFFFF
  font-size: 15px
  font-weight: 600
  line-height: 18px
  letter-spacing: 0
  text-align: center
  border-radius: 100%
  margin: 0 auto

.acc_agr_text_flex
  text-align: center
  font-size: 17px
  font-weight: 500
  line-height: 21px
  letter-spacing: 0
  margin: 6px 0 14px

.agreement_btn
  width: 190px
  height: 60px
  display: flex
  justify-content: center
  align-items: center
  border: 1px solid #546EDB
  color: #546EDB
  background: #fff
  font-size: 17px
  font-weight: 700
  line-height: 21px
  letter-spacing: 0
  border-radius: 10px
  transition: all 0.4s
  &:hover
    background: #546EDB
    color: #fff

.confirmed_modal_text_top
  font-size: 18px
  font-weight: 500
  line-height: 22px
  letter-spacing: 0
  text-align: center

.account_agreement_flex
  display: flex
  justify-content: space-between

.title_account_confirmed
  font-size: 32px
  font-weight: 800
  line-height: 35px
  letter-spacing: 0
  text-align: center

.acc_img_modal
  margin: 0 auto 16px

.acc_modal_conf_text
  font-size: 18px
  font-weight: 500
  line-height: 22px
  letter-spacing: 0
  text-align: center
  max-width: 370px
  margin: 0 auto

.acc_btn_blue
  width: 100%
  height: 60px
  border-radius: 10px
  display: flex
  justify-content: center
  align-items: center
  transition: all 0.4s
  background: rgba(84, 110, 219, 1)
  color: #fff
  font-size: 20px
  font-weight: 700
  line-height: 25px
  letter-spacing: 0
  border: 1px solid rgba(84, 110, 219, 1)
  &:hover
    color: rgba(84, 110, 219, 1)
    background: #fff

.withdraw_modal_text
  font-size: 18px
  font-weight: 500
  line-height: 22px
  letter-spacing: 0
  text-align: left

.cabinet_ads_admin.active rect
  fill: #fff

body
  font-family: 'Gilroy', sans-serif
  color: #000

body.noscroll
  overflow: hidden

.container_wrap
  padding: 0 40px

.container
  max-width: 1220px
  width: 100%
  padding: 0 20px
  margin: 0 auto

.relative
  position: relative

ul
  margin: 0
  padding-left: 0
  list-style-type: none

a
  text-decoration: none
  transition: all 0.3s

svg
  path
    transition: all 0.3s

button
  cursor: pointer
  -webkit-tap-highlight-color: rgba(0,0,0,0)
  &:focus
    outline: 0 !important

.error__react_module
  color: red
  font-size: 18px

h1, h2, h3, h4, h5, h6, p
  margin-top: 0

p
  margin-bottom: 0

.main
  min-height: 46.7vh
  position: relative
  z-index: 1
  flex-grow: 1
.main.fixedHeader
  padding-top: 132px

.radio__box_favorite
  margin-bottom: 50px

.main.bank_check
  background: transparent
  min-height: 955px

table
  border-collapse: collapse
td
  border: 1pt solid #000000
p
  margin: 0

//блокировка копировать-вставить если приложение
.copyBan
  -ms-user-select: none
  -moz-user-select: none
  -webkit-user-select: none
  user-select: none

.categories_flex
  display: flex
  flex-wrap: wrap
  gap: 20px
  justify-content: center
  margin-bottom: 110px
  .category_main_index
    width: 32.2%
    background: #F7F7FE
    border-radius: 8px
    position: relative
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: space-between
    .category_btn_wrap
      opacity: 0
      transition: all 0.4s
    &:hover
      .category_btn_wrap
        opacity: 1
  img
    width: 100%
  .category_main_item
    padding: 34px 36px
    .category_main_title
      font-size: 25px
      font-weight: 700
      color: #414253
      margin-bottom: 12px

.white
  background: #fff

.category_main_description
  color: #414253

.category_btn_wrap
  border-radius: 8px
  background: linear-gradient(96deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.50) 100%)
  backdrop-filter: blur(11.699999809265137px)
  position: absolute
  padding: 12px
  bottom: 16px
  left: 50%
  transform: translateX(-50%)
  button
    width: 220px
    height: 58px
    display: flex
    justify-content: center
    align-items: center
    border: none
    border-radius: 5px
    background: #546EDB
    font-size: 18px
    color: #fff

// Анимации и позиционирование

@keyframes opacityAnimation
  from
    opacity: 0
  to
    opacity: 1

@keyframes typing
  from
    width: 0
  to
    width: 86%

@keyframes blink-caret
  from, to
    border-color: transparent
  50%
    border-color: white



.l1, .l2, .l3, .l4, .l5, .r1, .r2, .r3, .r4, .r5
  opacity: 0
  animation: 3s opacityAnimation forwards

.l1 span, .l2 span, .l3 span, .l4 span, .l5 span, .r1 span, .r2 span, .r3 span, .r4 span, .r5 span
  overflow: hidden
  white-space: nowrap
  border-right: .15em solid white

.l1
  top: 16.2%
  left: 2.2%
  animation-delay: 0.4s
  span
    animation: 6s typing steps(44),  0.8s blink-caret infinite

.l2
  top: 40.5%
  left: 4.2%
  animation-delay: 0.8s
  span
    animation: 4s typing steps(44) 0.8s, 0.8s blink-caret infinite

.l3
  top: 45.4%
  left: 21.3%
  animation-delay: 1.2s
  span
    animation: 6s typing steps(44) 1.2s, 0.8s blink-caret infinite

.l4
  bottom: 31%
  left: 10.3%
  animation-delay: 1.6s
  span
    animation: 6s typing steps(44) 1.6s, 0.8s blink-caret infinite

.l5
  bottom: 11.2%
  left: 5%
  animation-delay: 2s
  span
    animation: 6s typing steps(44) 2s, 0.8s blink-caret infinite

.r1
  right: 10%
  top: 22%
  animation-delay: 2.4s
  span
    animation: 6s typing steps(44) 2.4s, 0.8s blink-caret infinite

.r2
  right: 1%
  top: 38%
  animation-delay: 2.8s
  span
    animation: 6s typing steps(44) 2.8s, 0.8s blink-caret infinite

.r3
  right: 16%
  bottom: 41%
  animation-delay: 3.2s
  span
    animation: 6s typing steps(44) 3.2s, 0.8s blink-caret infinite

.r4
  right: 7.7%
  bottom: 27%
  animation-delay: 3.6s
  span
    animation: 6s typing steps(44) 3.6s, 0.8s blink-caret infinite
.r5
  right: 3.5%
  bottom: 7%
  animation-delay: 4s
  span
    animation: 6s typing steps(44) 4s, 0.8s blink-caret infinite

.pl1
  top: 15%
  left: 12%
  animation-delay: 0.4s

.pl2
  top: 35%
  left: 4.8%
  animation-delay: 0.8s

.pl3
  bottom: 31%
  left: 1.8%
  animation-delay: 1.2s

.rl1
  right: 5.4%
  top: 20%
  animation-delay: 1.6s

.rl2
  right: 2%
  top: 50%
  animation-delay: 2s

.rl3
  bottom: 12%
  right: 1.8%
  animation-delay: 2.4s

.swiper-wrapper
  height: 99% !important

.mts_confirmed
  font-size: 14px
  margin-top: 10px
  color: rgb(84, 110, 219)

.partner_referral_flex
  .info_soc_block
    width: 100%
    p
      padding-right: 10px

.partner_referral_flex
  display: flex
  align-items: center
  gap: 20px
  flex-wrap: wrap

.btnTakeMoneyFromPartnerProgram
  width: 233px
  height: 47px
  display: flex
  justify-content: center
  align-items: center
  border-radius: 8px
  border: 1px solid #546EDB
  background: #546EDB
  color: #fff
  font-size: 15px
  font-weight: 700
  line-height: 18.57px
  transition: all 0.4s
  &:hover
    background: #FFFFFF
    color: #546edb
    border: 1px solid #546EDB

.benefit_left
  margin-bottom: 74px

.banner_left_title
  font-size: 40px
  font-weight: 800
  line-height: 44px
  letter-spacing: 0
  max-width: 480px

.banner_left_bottom
  background: linear-gradient(68.66deg, #546EDB 0%, #4088DD 100%, #546EDB 100%)
  padding: 32px 34px 38px 44px
  border-radius: 35px
  margin-top: 18px
  display: flex
  justify-content: space-between
  color: #FFFFFF
  width: 680px
  gap: 44px

.benefit_inner_title
  font-size: 20px
  font-weight: 600
  line-height: 25px
  letter-spacing: 0
  margin-top: 16px
  margin-bottom: 8px

.benefit_inner_description
  font-size: 16px
  font-weight: 400
  line-height: 19px
  letter-spacing: 0
  max-width: 280px
  margin-bottom: 16px

.benefit_button
  background: #FFFFFF
  border-radius: 30px
  width: 116px
  height: 39px
  display: flex
  justify-content: center
  align-items: center
  font-size: 16px
  font-weight: 500
  line-height: 19px
  letter-spacing: 0
  color: #546EDB
  cursor: pointer
  transition: all 0.4s
  &:hover
    background: #546edb
    color: #FFFFFF

.benefit_right
  margin-left: -180px
  img
    width: 100%

.convenient_search_title
  max-width: 750px
  margin: 0 auto
  text-align: center

.convenient_line
  display: flex
  justify-content: center

.convenient_block
  background-image: url("./components/index/convenientSearch/images/bg-block.png")
  background-repeat: no-repeat
  padding: 14px 6px 34px 30px
  width: 380px
  &:first-child
    margin-top: -60px

.convenient_flex
  display: flex
  justify-content: space-between

.convenient_top
  display: flex
  justify-content: space-between
  margin-bottom: 14px

.convenient_title
  max-width: 189px
  font-size: 18px
  font-weight: 600
  line-height: 22px
  letter-spacing: 0
  position: relative
  &:before
    content: ""
    display: block
    width: 49px
    height: 27px
    background: #EBEFFF
    border-radius: 50px
    position: absolute
    z-index: -1
    top: -2px
    left: -10px

.convenient_search
  margin-bottom: 80px
  position: relative

.convenient_description
  p
    max-width: 320px
    font-size: 17px
    line-height: 20px
    letter-spacing: 0
    margin-bottom: 22px

.convenient_img
  position: absolute
  z-index: -1
  left: 54%
  transform: translateX(-50%)
  top: 20%

.accommodation_main_flex
  display: flex
  align-items: flex-start
  margin-bottom: 75px

.accommodation_text_left
  font-size: 17px
  line-height: 20.4px
  max-width: 480px
  margin-bottom: 16px
  span
    font-weight: 500
    color: #546EDB

.accommodation_left_bottom
  border: 1px solid #BFCAF5
  display: flex
  justify-content: space-between
  border-radius: 6px
  max-width: 480px
  padding: 32px 46px 34px

.accommodation_bottom_block
  max-width: 96px
  &:last-child
    max-width: 138px

.accommodation_block_top
  position: relative

.accommodation_block_img
  img
    background: #fff

.accommodation_block_number
  position: absolute
  right: -6px
  top: -6px
  z-index: -1
  font-size: 64px
  font-weight: 300
  line-height: 77px
  letter-spacing: 0
  color: #546EDB
  opacity: 0.2

.accmlast
  right: 36px

.accommodation_right
  position: relative
  .accommodation_absolute
    position: absolute
    bottom: 0
    left: 0

.accommodation_block_text
  font-size: 15px
  line-height: 18px
  letter-spacing: 0

.category_left_categories
  margin-top: 14px
  display: flex
  flex-wrap: wrap
  gap: 10px

.category_left_category
  background: rgba(235, 239, 255, 1)
  padding: 6px 12px
  border-radius: 50px
  color: rgba(0, 0, 0, 1)

.category_left_category_active
  padding: 10px 12px
  background: #ffa800
  color: rgba(84, 110, 219, 1)
  min-width: 92px

.categories_left
  padding: 26px 6px 28px 30px
  background: linear-gradient(68.66deg, #546EDB 0%, #4088DD 100%, #546EDB 100%)
  position: relative
  border-radius: 35px
  color: #fff
  max-width: 500px
  &:after
    content: url("./components/index/categories/images/triangle.png")
    display: block
    position: absolute
    right: -34px
    top: 50%
    z-index: 3

.category_left_title
  font-size: 25px
  font-weight: 700
  line-height: 31px
  letter-spacing: 0
  margin-bottom: 6px

.category_left_text
  font-size: 16px
  line-height: 19.2px
  span
    background: rgba(111, 225, 184, 1)
    border-radius: 46px
    padding: 1px 4px 1px 4px
    color: rgba(84, 110, 219, 1)

.category_navLink
  display: flex
  margin-bottom: 35px
  max-height: 310px

.img_mid_radius
  border-radius: 35px
  height: 310px
  max-width: 380px
  object-fit: cover

.mid_category_radius
  position: relative
  max-width: 382px

.category_search_animation_technique
  position: absolute
  right: 56px
  top: 44px
  font-size: 14px
  font-weight: 500
  line-height: 15.77px
  color: #000
  border-radius: 10px
  background: #fff
  width: 220px
  z-index: 3

.category_right
  border: 1px solid #BFCAF5
  padding: 16px 15px 26px
  border-radius: 35px
  position: relative
  background: #fff
  min-width: 322px
  max-width: 322px
  &:before
    content: url("./components/index/categories/allCategories/components/categoryRight/triangle-right.svg")
    position: absolute
    left: -34px
    top: 50%

@keyframes fadeIn
  0%
    opacity: 0
  50%
    opacity: 1
  100%
    opacity: 0

.euip_hands, .euip_img
  position: absolute

.euip_hands
  z-index: 3

.width_opacity
  width: 380px
  position: relative
  img
    width: 100%
    height: 310px
  .euip_img
    left: 77px
    height: 180px
    width: 243px
    top: 70px

.partner_program_sub
  font-size: 18px
  line-height: 21.6px

.program_flex_partner
  display: flex
  gap: 4px
  img
    cursor: pointer

.admin_program_grid
  padding: 10px 0
  grid-template-columns: 0.1fr 0.1fr 0.6fr 0.3fr 0.36fr

.admin__content-card.program_content_grid
  padding: 8px
  grid-template-columns: 0.1fr 0.1fr 1fr 0.5fr 0.36fr 0.1fr
  position: relative
  img
    width: inherit
    height: inherit
    object-fit: inherit
    border-radius: 0

.program_partner_avatar_admin
  width: 50px !important
  height: 50px !important
  border-radius: 48px !important

.image_opacity
  opacity: 0
  animation: fadeIn 5s ease-in-out

.program_partner_accept_green, .program_partner_accept_red
  font-size: 18px
  font-weight: 700 !important
  line-height: 22.28px
  color: #6FE1B8

.program_partner_accept_red
  color: #FF2941

.category_search_animation_relative
  padding: 12px 15px
  position: relative
  border-radius: 10px
  background: #fff
  &:before
    content: ""
    display: block
    position: absolute
    z-index: -1
    left: -1px
    right: -40px
    top: -1px
    border-radius: 10px
    bottom: -1px
    background: #546edb
  &:after
    content: url("./components/index/categories/images/middle/technique/search.svg")
    display: block
    position: absolute
    right: -25px
    top: 12px
  span
    width: 0
    display: block
    overflow: hidden
    white-space: nowrap
    animation: 5s typing infinite

.category_mid_images
  position: absolute
  bottom: 30px
  right: 50px

@keyframes fadeInImage
  from
    opacity: 0
  to
    opacity: 1

.category_mid_image
  opacity: 0
  animation: 5s fadeInImage infinite

.partner_program_statistic_money_block
  border: 1px solid #DDE2F8
  border-radius: 14px
  background: #fff
  margin-bottom: 16px
  display: flex

.partner_program_block_moneyPoints
  padding: 18px 14px 10px 14px
  border-right: 1px solid #DDE2F8
  &:last-child
    border-right: none

.partner_program_block_title_moneyPoints
  font-size: 18px
  font-weight: 500
  line-height: 21.83px

.partner_program_block_data
  font-size: 22px
  font-weight: 600
  line-height: 26.95px
  color: #546EDB


.category_index_amount
  border: 1px solid #BFCAF5
  position: absolute
  left: 0
  top: -1px
  right: 0
  padding: 24px 26px 23px 33px
  background: #fff
  z-index: 2
  border-radius: 35px
  display: flex
  align-items: center
  gap: 8px
  color: #000

.category_index_amount_circle
  min-width: 14px
  max-height: 14px
  min-height: 14px
  background: #5BC6A0
  border-radius: 100%

.category_index_amount_text
  font-size: 12px
  line-height: 14.4px

.category_index_amount_number
  font-size: 32px
  line-height: 38.4px
  white-space: nowrap

.category_right_block
  position: relative
  border-radius: 8px
  color: #fff
  margin-bottom: 10px
  display: flex
  &.category_right_block_green
    background: #5BC6A0
  &.category_right_block_blue
    background: #546edb

.category_right_block_top
  display: flex
  align-items: center
  img
    margin-right: 4px

.annotation_category
  font-size: 10px
  line-height: 12px

.category_right_block_right
  padding: 14px 12px 14px 10px

.title_index_category
  font-size: 13px
  line-height: 15.6px

.specialists_img
  padding: 12px
  border-radius: 50px

.category_right
  .slick-list
    padding: 0 !important
  .slick-slide
    opacity: 0.6
  .slick-center
    opacity: 1

.category_specialists_absolute
  position: absolute
  top: 40px
  left: 10px
  .swiper-slide
    width: 117px !important
    opacity: 0.6
  .swiper-wrapper
    max-width: 360px
  .swiper-slide-next
    opacity: 1

@media (max-width: 1750px)
  .l1
    top: 24.2%
    left: -0.8%
  .l2
    top: 37.5%
    left: 2.2%
  .l3
    left: 10.3%
  .l4
    left: 3.3%
  .r1
    right: 0
    top: 25%
  .r3
    right: 7%
    bottom: 38%
  .r5
    right: 0.5%
    bottom: 13%

@media (max-width: 1200px)
  .l1, .l2, .l3, .l4, .l5, .r1, .r2, .r3, .r4, .r5
    display: none
  .category_navLink
    zoom: 0.8

@media (max-width: 1200px)
  .category_navLink
    zoom: 0.74
  .accommodation_right
    zoom: 0.9

@media (max-width: 1199px)
  .l5, .r4, .l4
    display: block

@media (max-width: 876px)
  .category_navLink
    max-height: inherit

@media (max-width: 768px)
  .categories_flex .category_main_item .category_main_title
    font-size: 20px
    margin-bottom: 8px
  .categories_flex .category_main_index
    width: 48.2%
  .pl1, .pl2, .pl3, .rl1, .rl2, .rl3
    display: none
  .modal_account_agreement
    padding: 26px 35px 22px
  .agreement_btn
    width: 140px
    height: 45px
    font-size: 11px
    line-height: 13.62px
    border-radius: 5px
  .input-file span
    width: 120px
    height: 45px
    font-size: 11px
    line-height: 13.62px
    border-radius: 5px
  .acc_agr_text_flex
    font-size: 13px
    line-height: 15.77px
  .acc_btn_blue
    font-size: 18px
  .benefit_flex
    display: block
  .benefit_right
    margin-left: 0
    img
      width: inherit
  .benefit_left
    margin-bottom: 20px
  .banner_left_title
    max-width: 100%
    font-size: 30px
    line-height: 110%
  .banner_left_bottom
    width: 100%
  .banner_left_bottom
    display: block
    padding: 20px 14px 34px 20px
  .banner_inner_left
    margin-bottom: 24px
  .benefit_inner_description
    max-width: 100%

@media (max-width: 580px)
  .categories_flex .category_main_index
    width: 46.2%
  .category_navLink
    zoom: 1
    display: block
  .mid_category_radius
    max-width: 100%
  .img_mid_radius
    width: 100%
    max-width: 100%
  .category_right
    min-width: 100%
    max-width: 100%
    width: 100px
  .width_opacity
    width: 100%
  .categories_left
    max-width: 100%
  .categories_left
    background: #4484DD
  .categories_left:after
    top: inherit
    right: 125px
    transform: rotate(90deg)
    bottom: -13%
    zoom: 0.8
  .category_mid_images
    bottom: 30%
  .category_specialists_absolute
    top: 65px
    left: 15%
  .category_left_title
    font-size: 22px
    line-height: 27.24px
  .category_left_text
    p
      font-size: 15px
  .category_left_category
    font-size: 12px
  .flex-category
    justify-content: inherit
  .width_opacity .euip_img
    left: 26%
    height: 188px
    width: 286px
    top: 67px
    transform: rotate(1deg)

@media (max-width: 460px)
  .btnTakeMoneyFromPartnerProgram, .partner_referral_flex .info_soc_toggle
    width: 100%
  .rl1, .rl2, .rl3
    display: block
  .rl1
    top: inherit
    left: 18%
    right: inherit
    bottom: 47%
  .rl2
    right: 55%
    top: 61%
  .rl3
    bottom: 46%
    right: 2.8%
  .category_mid_images
    bottom: 20%
  .category_specialists_absolute
    top: 18%
    left: 10%
  .width_opacity .euip_img
    left: 21%
    height: 186px
    width: 253px
    top: 67px
    transform: rotate(0deg)

@media (max-width: 420px)
  .l2, .l5, .r4, .l3
    display: block
  .l4
    display: none
  .partner_program_statistic_money_block
    flex-wrap: wrap
  .l5
    bottom: 42.2%
    left: 8%
  .l2
    top: inherit
    left: 54.2%
    bottom: 35%
  .l3
    left: inherit
    top: inherit
    bottom: 25%
    right: 10%
  .r4
    right: inherit
    left: 10%
  .categories_flex .category_main_index
    width: 46.2%
  .categories_flex .category_main_item
    padding: 12px
  .categories_flex
    margin-bottom: 32px
  .modal.modalOrdered.modal_account_confirmed
    width: 100%
    height: 300px
    padding: 26px 24px 30px
  .acc_img_modal
    max-width: 180px
    margin: 0 auto
  .title_account_confirmed
    font-size: 20px
    line-height: 22px
  .acc_modal_conf_text
    font-size: 13px
    line-height: 15.77px
  .acc_btn_blue
    width: 100%
    height: 39px
    font-size: 12.89px
    line-height: 15.96px
  .modal.modalOrdered.modal_account_withdraw
    width: 100%
    height: 190px
    .input.input_payment input
      height: 38px
    .input .icon
      bottom: 6px
      zoom: 0.8
    .input.input_payment
      margin-bottom: 10px
  .withdraw_modal_text
    font-size: 13px
  .modal_account_agreement
    width: 100%
  .category_mid_images
    bottom: 14%
  .category_specialists_absolute
    left: 4%
  .category_left_categories
    gap: 6px
  .width_opacity .euip_img
    left: 19%
    width: 245px

@media (max-width: 820px)
  .convenient_flex
    display: block
  .convenient_img
    left: inherit
    transform: inherit
    top: 33%
    right: -16%
  .convenient_block:first-child
    margin-top: 0
  .accommodation_main_flex
    display: block
  .accommodation_left_bottom
    margin-bottom: 20px
  .accommodation_left_bottom
    max-width: 100%
  .accommodation_image
    width: 100%

.convenient_mobile_lineB
  margin-top: -22px
  margin-left: 32px


@media (max-width: 560px)
  .convenient_img
    position: relative
    top: inherit
    right: inherit
    width: 100%
    margin-top: 12px
  .convenient_block
    width: 290px
    background-size: contain
    padding: 10px 16px 10px 18px
    background-image: url("./components/index/convenientSearch/images/bg-block-m.png")
    .convenient_top
      img
        width: 44px
        height: 44px
    p
      margin-bottom: 16px
  .convenient_description p
    font-size: 14px
    line-height: 17px
    letter-spacing: 0
  .convenient_title
    font-size: 16px
    font-weight: 600
    line-height: 20px
    letter-spacing: 0
  .convenient_mobile_lineB
    margin-top: 0
  .convenient_line
    margin-left: -82px
    margin-bottom: 14px
  .accommodation_left_bottom
    padding: 16px 9px 16px 15px
  .accommodation_right .accommodation_absolute
    zoom: 0.8

@media (max-width: 420px)
  .accommodation_right .accommodation_absolute
    zoom: 0.7
  .accommodation_left_bottom
    display: block
  .accommodation_bottom_block
    max-width: 100%
    margin-bottom: 14px
    display: flex
    align-items: center
  .accommodation_block_text
    margin-left: 8px
    font-size: 14px
  .accommodation_block_img
    zoom: 0.8
  .accommodation_bottom_block:last-child
    max-width: 100%
  h2
    font-size: 24px
    line-height: 26.4px

@media (max-width: 360px)
  .modal_account_agreement
    padding: 26px 16px 22px
  .category_specialists_absolute
    zoom: 0.9
  .accommodation_right .accommodation_absolute
    zoom: 0.58
  .width_opacity .euip_img
    left: 16%
    width: 246px

@media (max-width: 320px)
  .l5
    left: 24.2%
    bottom: 36.2%
  .l2
    top: inherit
    left: 54.2%
    bottom: 26%
  .agreement_btn
    width: 120px
  .modal_account_agreement
    height: 468px
  .accommodation_right .accommodation_absolute
    zoom: 0.5
  .width_opacity .euip_img
    left: 12%
    width: 244px

@import './styles/sliders.sass'
@import './styles/media.sass'
