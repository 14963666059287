.flex{
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}

.btn{
  background: #546EDB;
  color: #fff;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 6px;
  transition: all 0.4s;
  &:hover{
    border: 1px solid #546EDB;
    background: #fff;
    color: #546EDB;
  }
}

.flex_title{
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 42px;
  svg{
    cursor: pointer;
  }
}

.title_history{
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 3fr;
  margin-bottom: 20px;
  padding-left: 12px;
  p{
    font-weight: 800;
    font-size: 16px;
    color: #C4C4C4;
  }
}

h3{
  margin-bottom: 0;
}

.item_history{
  box-shadow: 1px 4px 8px 0 rgba(34, 60, 80, 0.2);
  padding: 14px 14px 10px 12px;
  border-radius: 14px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 5fr;
  p{
    font-size: 14px;
  }
}

.history_flex{
  display: grid;
  gap:10px;
  margin-bottom: 8px;
  grid-template-columns: 2fr 1.5fr 0.2fr 1fr;
}

.item_history_adv{
  box-shadow: 1px 4px 8px 0 rgba(34, 60, 80, 0.2);
  padding: 14px 14px 10px 12px;
  border-radius: 14px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2.5fr 0.5fr;
  p{
    font-size: 14px;
  }
}

.icon{
  display: flex;
  align-self: center;
  cursor: pointer;
}