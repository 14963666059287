.card
    width: 100%
    padding: 15px
    border-radius: 12px
    display: flex
    flex-direction: column
    align-items: center
    gap: 15px
    &.fix
        border: 2px solid #63D3AB
        a
            color: #546EDB
            background: #6FE1B8
        .content
            h3 span
                color: #6FE1B8
        .item
            border: 1px solid #63D3AB

    &.eye

        border: 2px solid #546EDB
        a
            color: #6FE1B8
            background: #546EDB
        .content
            h3 span
                color: #546EDB
        .item
            border: 1px solid #546EDB

    a
        padding: 15px 30px
        border-radius: 6px
        border: none
        font-weight: 700
        font-size: 16px
        line-height: 20px
