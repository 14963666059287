.wrapper
    width: 100%
    padding: 15px
    border-radius: 12px
    display: flex
    flex-direction: column
    align-items: center
    gap: 15px
    &.fix
        border: 2px solid #63D3AB
        button
            color: #546EDB
            background: #6FE1B8
        .content
            h3 span
                color: #6FE1B8
        .item
            border: 1px solid #63D3AB

    &.eye
        border: 2px solid #546EDB
        button
            color: #6FE1B8
            background: #546EDB
        .content
            h3 span
                color: #546EDB
        .item
            border: 1px solid #546EDB

    button
        padding: 15px 30px
        border-radius: 6px
        border: none
        font-weight: 700
        font-size: 16px
        line-height: 20px

.content
    text-align: center
    h3
        font-weight: 600
        font-size: 20px
        line-height: 25px

.descr
    margin-bottom: 10px
    font-weight: 400
    font-size: 15px
    line-height: 18px

.item
    border-radius: 4px

    &:not(:last-child)
        margin-bottom: 10px

    .input_wrapper
        display: flex
        gap: 10px
        padding: 10px

    .price
        p
            font-weight: 700
            font-size: 18px
            line-height: 22px
            span
                color: #858585
                font-size: 12px
                line-height: 14px
                text-decoration: line-through

.price
    display: flex
    flex-direction: column
    text-align: start

.sale
    font-weight: 400
    font-size: 12px
    line-height: 14px

.cards_list
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    gap: 10px
    padding: 25px

.rate_wrap
    width: 100%

@media screen and (max-width: 1200px)
    .cards_list
        grid-template-columns: 1fr 1fr

@media screen and (max-width: 576px)
    .cards_list
        grid-template-columns: 1fr
    .content
        h3
            margin-bottom: 10px
    .wrapper
        button
            padding: 15px 28px
