@import "../../styles/variables.sass"

.terms
    &__content_wrapper
        padding-right: 20px
    &_container
        height: 100%
        display: flex
        gap: 20px
        max-height: 700px

    &__blocks_wrapper
        display: flex
        background: #FFFFFF
        border: 1px solid #DDE2F8
        border-radius: 14px
        height: 100%

    &_list
        height: 100%
        width: 40%
        padding: 40px 20px
        background: #ffffff
        border-right: 1px solid #DDE2F8
        border-radius: 14px
        h6
            margin-bottom: 42px
            font-size: 12px
            color: #858585
            font-family: "Roboto", sans-serif
            font-weight: 700
            line-height: 14px

        .title
            background: #f6f7fe
            border: 1px solid rgba(84, 110, 219, 0.2)
            border-radius: 10px
            padding: 20px 15px 20px 55px
            @include p-18
            overflow: hidden
            cursor: pointer
            position: relative
            transition: 0.3s color ease-in-out, 0.3s background-color ease-in-out
            &.active
                color: #ffffff
                background-color: #546edb

            &:not(:last-child)
                margin-bottom: 10px

            &:hover
                color: #ffffff
                background-color: #546edb

            span
                position: absolute
                top: 50%
                left: -15px
                transform: translateY(-50%)
                color: #B6BFE7
                opacity: 0.3
                font-weight: 700
                font-size: 64px
                line-height: 79px
    &_item
        border-radius: 10px
        width: 70%
        padding: 40px 20px
        overflow-y: auto
        &::-webkit-scrollbar
            background: #E7EAF5
            border-radius: 20px
        &::-webkit-scrollbar-button
            background: #546EDB
            border-radius: 20px

@media (max-width: 1024px)
    .terms
        &_container
            flex-direction: column
            gap: 0

        &_list,
        &_item
            width: 100%

        &_list
            padding: 15px 10px
            .title
                padding: 15px 10px 15px 60px
                @include p-16-600

        &_item
            height: 500px
            padding: 10px
            background: #FFFFFF
            border: 1px solid #DDE2F8
            border-radius: 10px
