.top
    display: flex
    justify-content: space-between
.word
    background: #FFFFFF
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1)
    border-radius: 14px
    margin-bottom: 20px
    padding: 10px
    display: flex
    justify-content: space-between
.delete
    background: transparent
    border: none
    display: flex
    align-items: center
    justify-content: center
    &:hover
        circle
            fill: #fff
        path
            fill: rgb(84, 110, 219)

.flex
    display: flex
    align-items: center
