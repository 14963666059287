.layout
    height: 100%

.top_wrapper
    display: flex
    justify-content: space-between
    margin-bottom: 40px
    button
        margin: 0

    .title
        display: flex
        align-items: center
        gap: 5px
        div
            cursor: pointer
        h3
            margin: 0
.photos_wrapper
    margin-bottom: 50px
    display: flex
    gap: 50px
    &__title
        margin-bottom: 30px
        font-weight: 700

.bottom_wrapper
    width: 50%
    margin-bottom: 20px
    input
        width: 100%
        margin-bottom: 20px
        padding: 0 10px
        height: 48px
        background: #f6f7fe
        border-radius: 10px
        font-family: "Gilroy", sans-serif
        font-weight: 500
        font-size: 14px
        line-height: 16px
        color: #414253
        border: 1px solid rgba(84, 110, 219, 0.2)
.success
    position: absolute
