.modal
    max-width: 450px
    h3
        font-weight: 800
        font-size: 32px
        line-height: 110%
        margin-bottom: 20px
        text-align: center
    img
        margin: 0 auto
        margin-bottom: 10px
    .content_wrapper
        display: flex
        justify-content: center
        gap: 25px
    .content
        border-radius: 12px
        padding: 20px 5px 5px 5px
        width: 100%
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center
        div
            display: flex
            flex-direction: column
            margin-bottom: 10px
            align-items: center
            gap: 10px

        svg
            text-align: center
        a
            display: inline-block
            border-radius: 10px
            width: 100%
            text-align: center
            padding: 10px
            font-weight: 700
            font-size: 16px
            line-height: 20px
        p
            font-weight: 500
            font-size: 18px
            line-height: 22px
            text-align: center
        &.search
            border: 2px solid #63D3AB
            span
                color: #63D3AB
            a
                background-color: #63D3AB
                color: #546EDB

        &.get
            border: 2px solid #546EDB
            span
                color: #546EDB
            a
                background-color: #546EDB
                color: #63D3AB

@media screen and (max-width: 576px)
    .modal
        max-width: 95%
        h3
            font-size: 24px
            line-height: 110%
            margin-bottom: 15px
        img
            width: 86px
        .content_wrapper
            gap: 15px
        .content
            svg
                width: 70px
                height: auto
            p

                font-size: 13px
                line-height: 16px
            a
                padding: 10px
                font-size: 12px
                line-height: 15px
                border-radius: 8px
