.special
    color: #546EDB
.btn
    padding: 17px
    color: #fff
    background-color: #546EDB
    border: none
    font-weight: 700
    font-size: 20px
    line-height: 25px
    border-radius: 10px
    &.disabled
        background-color: #6D86EC
        cursor: default
.title
    margin-bottom: 10px
    font-weight: 800
    font-size: 36px
    line-height: 110%
    text-align: center
.descr
    margin-bottom: 15px
    font-weight: 500
    font-size: 18px
    line-height: 22px
    text-align: center
.note
    margin-bottom: 18px
    text-align: center
    font-size: 12px
    line-height: 14px
.modal__nonconfirm
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 100%
    max-width: 450px
    padding: 50px 40px
    position: fixed
    z-index: 100
    left: 50%
    top: 50%
    border-radius: 30px
    transform: translate(-50%, -50%)
    background: #FFFFFF
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
    img
        margin: 0 auto
        max-width: 296px
        margin-bottom: 16px

@media screen and (max-width: 576px)
    .btn
        padding: 10px
        color: #fff
        background-color: #546EDB
        font-size: 14px
        line-height: 17px
        border-radius: 6px
    .title
        margin-bottom: 5px
        font-weight: 800
        font-size: 24px
        line-height: 110%
    .descr
        margin-bottom: 10px
        font-size: 13px
        line-height: 16px
    .note
        margin-bottom: 10px
        font-size: 8px
        line-height: 10px
    .modal__nonconfirm
        padding: 30px
        max-width: 290px
        img
            max-width: 190px
            margin-bottom: 12px
