.tox-tinymce {
    z-index: 0;
}

.admin_one_user_information{
    width: 126px;
}

.admin_one_user_info_name{
    margin-bottom: 4px;
}

.advs_moderated_block{
    display: flex;
    gap: 40px;
    padding: 0 22px 20px;
    flex-wrap: wrap;
    .advs_moderated_title{
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 8px;
        span{
            font-weight: 400;
        }
    }
}

.advs_moderated_block_left{
    img{
        border-radius: 18px;
    }
}

.star_admin{
    color: #FFA800;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 600;
}

.admin__content-card_wrapper{
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    margin-bottom: 20px;
    .admin-user_moderated__bottom{
        padding: 0 22px 20px;
        max-width: 55%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
    .admin-user_organization_title{
        font-weight: 700;
        font-size: 14px;
        span{
            font-weight: 400;
        }
    }
}

.block_user_is_confirmed{
    max-width: 236px;
    border-radius: 100px;
    border: 1px solid #2AA020;
    padding: 6px;
    display: flex;
    align-items: center;
    margin-top: 12px;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
    &.docs{
        border-color: #546edb;
    }
    .block_user_is_confirmed_text{
        font-family: Roboto, sans-serif;
        font-size: 15px;
        color: #2AA020;
        &.docs{
            color: #546edb;
        }
    }
    .block_user_is_confirmed_checked{
        width: 18px;
        height: 18px;
        border: 1px solid #2AA020;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        margin-right: 8px;
        margin-top: -1px;
        &.docs{
            border-color: #546edb;
        }
    }
    .block_user_is_confirmed_checked_true{
        margin-right: 8px;
        margin-top: -2px;
        height: 16px;
    }
}

.fieldset_admin{
    padding: 0;
    max-width: 520px;
}

.person_data.person_data_admin .input input{
    padding: 0 15px;
}

.person_data.person_data_admin{
    border: none;
}

.flex_admin_center{
    display: flex;
    justify-content: center;
    max-width: 140px;
}

.star_admin{
    svg{
        margin-right: 4px;
        margin-left: 6px;
    }
}

.admin__rating, .no-rating{
    font-family: Roboto, sans-serif;
    font-size: 15px;

    font-weight: 300;
}

.admin_block_flex{
    display: flex;
    flex-direction: column;
}

.admin_wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    justify-content: space-between;
}

.admin_one-user_back{
    .admin_back_wrap_back_btn{
        svg path{
            fill: #546EDB;
        }
    }
}

.flex{
    .date_admin{
        cursor: auto;
        color: #546EDB;
        font-family: Gilroy, sans-serif;
        font-size: 16px;
        margin-right: 0;
        margin-left: 12px;
    }
}

.delete_in_admin_btn{
    border: 1px solid transparent;
    width: 170px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #D32121;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    transition: all 0.4s;
    &:hover{
        border: 1px solid #D32121;
        background: #fff;
        color: #D32121;
    }
}

.admin_one_user_img_infoFlex{
    display: flex;
    align-items: center;
    img{
        margin-right: 18px;
    }
}

.admin {
    &__search-wrapper {
        width: 50%;
        min-width: 250px;
        margin-bottom: 20px;
        p{
            margin-bottom: 8px;
        }
    }
    width: 100%;
    &__users_btns-wrapper {
        display: flex;
        gap: 5px;
    }
    &__users_navigation_calendar {
        margin-bottom: 20px;
    }
    &_one-user_objects_tabs {
        position: relative;
        min-height: 200px;
    }
    a {
        justify-self: center;
        &:hover {
            .back path {
                fill: #546edb;
            }
        }
    }
    &__success {
        color: green;
        font-size: 14px;
        text-align: right;
    }
    &__error {
        display: block;
        margin-top: 10px;
        color: red;
        font-size: 14px;
    }
    &__back-arrow {
        color: inherit;
        outline: none;
        border: none;
        background: transparent;
        svg path {
            fill: black;
        }
    }
    &__layout {
        width: 100%;
        height: 100%;
        padding: 42px 35px 70px 18px;
        background: #ffffff;
        border: 1px solid #dde2f8;
        border-radius: 10px;
        position: relative;
        h3 {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
        }
    }

    &__content-card {
        padding: 15px 22px;
        margin-bottom: 20px;
        align-items: center;
        background: #ffffff;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 14px;
        display: grid;
        gap: 20px;
        &:not(:last-child) {
            margin-bottom: 20px;
        }

        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
        }
        p,
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
        }
        &_rating {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        &-icons {
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: flex-end;
        }

        &_users {
            grid-template-columns: 0.5fr 0.5fr 2fr 2fr 2fr 0.5fr 0.5fr;
        }
        &_roles {
            grid-template-columns: 0.5fr 3fr 2fr 2fr 2fr 0.2fr;
        }
        &_categories {
            grid-template-columns: 1fr 1.5fr 1.4fr 1.4fr 1fr 0.8fr 1fr;
            width: 100%;
            gap: 20px;
        }
        &_subcategories {
            grid-template-columns: 1fr 2fr 1.7fr 1.8fr 1.5fr 1fr 2fr;
            width: 100%;
            gap: 20px;
        }
        &_statistic {
            grid-template-columns: 0.5fr 0.5fr 1.5fr 2.5fr 2fr 0.5fr;
            gap: 10px;
        }
        &_subwrapper {
            padding-left: 35px;
        }
        &_childwrapper {
            padding-left: 60px;
        }
        &_childrens {
            grid-template-columns: 1fr 1.6fr 1.3fr 1fr 1fr 1fr;
        }
        &_btns {
            display: flex;
            align-items: center;
            gap: 20px;
            justify-content: flex-end;
            a {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #f7f7fe;
                border-radius: 14px;
                transition: background 0.3s ease-in-out;
                &:hover {
                    background: #546edb;

                    .addIcon path {
                        stroke: #fff;
                    }
                    .editIcon path {
                        fill: #fff;
                    }
                }
            }
        }
    }

    &__list-titles {
        display: grid;
        padding: 15px 22px;
        margin-bottom: 16px;
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        color: #c4c4c4;
        gap: 15px;

        &_obj {
            grid-template-columns: 0.2fr 1fr 2fr 2fr 1fr 0.3fr;
        }
        &_money {
            grid-template-columns: 1.5fr 3fr 1fr 1.5fr 1fr;
        }
        &_history {
            grid-template-columns: 1.5fr 3fr 1fr 1.5fr;
        }
        &_categories {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
        }
        &_users {
            grid-template-columns: 0.5fr 0.5fr 2fr 2fr 2fr 0.5fr 0.5fr;
        }
        &_roles {
            grid-template-columns: 0.5fr 3fr 2fr 2fr 2fr 0.2fr;
        }
        &_statistic {
            grid-template-columns: 0.5fr 0.5fr 1.5fr 2.5fr 2fr 0.5fr;
            gap: 10px;
            &_obj {
                grid-template-columns: 0.5fr 1fr 2.5fr 1fr 2fr 0.5fr;
            }
            &_order {
                grid-template-columns: 0.5fr 3fr 2fr 1fr 1fr;
            }
            &_mark {
                grid-template-columns: 1fr 4fr 1fr 1fr;
            }
        }
    }

    &__objects_object {
        display: grid;
        grid-template-columns: 0.2fr 1fr 2fr 2fr 1fr 0.3fr;
        gap: 15px;
        padding: 15px 22px;
        align-items: center;
        background: #ffffff;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 14px;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        img {
            width: 100%;
            height: 50px;
            object-fit: cover;
            border-radius: 10px;
        }
        p,
        span {
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
        }
        &-name {
            max-width: 200px;
        }
        &_statistic_obj {
            grid-template-columns: 0.5fr 1fr 2.5fr 1fr 2fr 0.5fr;
        }
        &_history {
            display: grid;
            gap: 15px;
            margin-bottom: 20px;
            padding: 15px 22px;
            grid-template-columns: 1.5fr 3fr 1fr 1.5fr;
            align-items: center;
            background: #ffffff;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            border-radius: 14px;
            &_btns {
                display: flex;
                gap: 10px;
                button {
                    border: none;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35px;
                    height: 35px;
                    transition: background 0.3s ease-in-out;
                }
                .approve {
                    background: #6fe1b8;
                    &:hover {
                        background: #99f3d2;
                    }
                }
                .reject {
                    background: #ff7887;
                    &:hover {
                        background: #fcbbc2;
                    }
                }
            }
        }
        &_money {
            display: grid;
            gap: 15px;
            margin-bottom: 20px;
            padding: 15px 22px;
            grid-template-columns: 1.5fr 3fr 1fr 1.5fr 1fr;
            align-items: center;
            background: #ffffff;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            border-radius: 14px;
            &_btns {
                display: flex;
                gap: 10px;
                button {
                    border: none;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 35px;
                    height: 35px;
                    transition: background 0.3s ease-in-out;
                }
                .approve {
                    background: #6fe1b8;
                    &:hover {
                        background: #99f3d2;
                    }
                }
                .reject {
                    background: #ff7887;
                    &:hover {
                        background: #fcbbc2;
                    }
                }
            }
        }
        &_statistic_order {
            grid-template-columns: 0.5fr 3fr 2fr 1fr 1fr;
        }
        &_statistic_mark {
            grid-template-columns: 1fr 4fr 1fr 1fr;
        }
    }

    &__waiting {
        background-image: url(../assets/images/admin/waiting.svg);
    }

    &__delete {
        background-image: url(../assets/images/admin/delete.svg);
    }

    &__done {
        background-image: url(../assets/images/admin/done.svg);
    }

    &__rejected {
        background-image: url(../assets/images/admin/delete.svg);
    }

    &__deactive {
        background-image: url(../assets/images/admin/delete.svg);
    }

    &__waiting,
    &__done,
    &__rejected,
    &__deactive,
    &__delete {
        padding-left: 20px;
        background-repeat: no-repeat;
        background-position: left center;
    }

    &__btn-blue {
        width: 150px;
        height: 40px;
        background: #546edb;
        border-radius: 10px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Gilroy", sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
        transition: all 0.3s ease;
        &:hover {
            background: #fff;
            border: 1px solid #546edb;
            color: #546edb;
        }
    }

    &__btn-transparent {
        width: 150px;
        height: 40px;
        background: #fff;
        border-radius: 10px;
        border: 1px solid #546edb;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Gilroy", sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #546edb;
        transition: all 0.3s ease;
        cursor: pointer;
        &:hover, &.active{
            background: #546edb;
            border: 1px solid #fff;
            color: #fff;
        }
    }
}

.admin__statistic {
    &_back {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
        h3 {
            margin-bottom: 0;
        }
    }
    &_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        &_btn {
            margin-bottom: 20px;
            display: flex;
            gap: 10px;
        }
    }
    h3 {
        margin-bottom: 0;
    }
    &_model-tabs {
        display: flex;
        gap: 15px;
        margin-bottom: 30px;
        div {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            opacity: 0.3;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            &:hover {
                color: #546edb;
            }
        }
        .active {
            opacity: 1;
            color: #546edb;
        }
    }
    &_obj {
        grid-template-columns: 1fr 1.5fr 2fr 2fr 0.5fr;
    }
    &_navigation {
        margin-bottom: 20px;
        &_right {
            display: flex;
            gap: 15px;
            div {
                padding: 10px;
                display: flex;
                gap: 10px;
                align-items: center;
                border-radius: 12px;
                cursor: pointer;
                transition: all ease-in-out 0.3s;
            }
            span {
                font-weight: 600;
            }
            &_all {
                border: 1px solid #546edb;
                &.active {
                    background: #546edb;
                    color: #fff;
                }
                &:hover {
                    background: #546edb;
                    color: #fff;
                }
            }
            &_approve {
                border: 1px solid green;
                &.active {
                    background: green;

                    color: #fff;
                }
                &:hover {
                    background: green;
                    color: #fff;
                }
            }
            &_reject {
                border: 1px solid rgba(241, 56, 16, 1);
                &.active {
                    background: rgba(241, 56, 16, 1);
                    color: #fff;
                }
                &:hover {
                    background: rgba(241, 56, 16, 1);
                    color: #fff;
                }
            }
        }
        &_calendar {
            display: flex;
            align-items: center;
            .input {
                max-width: 130px;
                background: #f7f7fe;
                border-radius: 14px;
                padding: 15px;
                border: none;
                margin-right: 10px;
            }
            .nice-dates-popover {
                max-width: 350px;
            }
        }
        &_left {
            display: flex;
            gap: 20px;
            align-items: center;
            margin-bottom: 20px;
            justify-content: space-between;
            &_search {
                position: relative;
                button {
                    position: absolute;
                    right: 10px;
                    height: 100%;
                    background: transparent;
                    border: none;
                    &:hover svg path {
                        stroke: #546edb;
                    }
                    svg path {
                        stroke: #171a23;
                    }
                }
                input {
                    background: #f7f7fe;
                    border-radius: 14px;
                    width: 100%;
                    padding: 15px;
                    border: none;
                }
            }
        }
    }
}

.admin__edit-category {
    &_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        h3 {
            margin: 0;
        }
        div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }
    }
    &_inputs {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    input {
        width: 100%;
        padding: 20px;
        border: none;
        background: #f6f8f9;
        border-radius: 5px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #171a23;
    }
    &_labels-wrapper {
        display: flex;
        gap: 20px;
        .selects {
            width: 50%;
            cursor: pointer;
        }
        label {
            width: 50%;
            p {
                margin-bottom: 20px;
                font-weight: 800;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
    &_error {
        position: absolute;
    }
}

.admin_block-btn {
    width: 40px;
    height: 40px;
    background: #ff7887;
    border-radius: 10px;
    outline: none;
    border: none;
}

.admin__info {
    max-width: unset;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    &_top-wrapper {
        display: flex;
        justify-content: space-between;
        &_admin {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }
    }

    &_btns {
        display: flex;
        gap: 10px;
        flex-direction: column;
        &-wrapper {
            display: flex;
            gap: 10px;
        }
    }

    &_profile {
        flex-direction: column;
    }

    &_container {
        display: grid;
        height: 100%;
        gap: 20px;
        &.grid {
            grid-template-columns: 2fr 1fr;
        }
    }

    .input {
        width: 45%;
    }

    &_email {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .input {
            margin-bottom: 0;
            margin-right: 10px;
        }

        span {
            font-weight: 700;
            font-size: 14px;
            color: #546edb;
            cursor: pointer;
        }
    }

    .box_input {
        margin-bottom: 20px;
    }

    &_change {
        padding: 20px;
        &_password-container {
            margin-bottom: 10px;
        }

        h6 {
            margin-bottom: 15px;
        }

        .input {
            margin-bottom: 12px;
        }

        span {
            display: block;
            text-align: end;
            font-weight: 700;
            font-size: 14px;
            color: #546edb;
            cursor: pointer;
        }
    }

    &_notification {
        display: flex;
        align-items: center;
        label {
            margin-right: 20px;
            background: #f2f5fa;
            border-radius: 40px;
            height: 30px;
            width: 54px;
            cursor: pointer;
            position: relative;
            span {
                position: absolute;
                width: 26px;
                height: 26px;
                top: 2px;
                left: 2px;
                background-color: #4088dd;
                border-radius: 50%;
                transition: transform 0.3s ease-in-out;
            }
            #checkbox {
                visibility: hidden;

                &:checked ~ span {
                    transform: translateX(24px);
                }
            }
        }
        p {
            color: #414253;
        }
    }
}

.admin_one-object {
    &_top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        padding-bottom: 27px;
        border-bottom: 1px solid #c4c4c4;
        position: relative;
        .admin_one-object_success {
            position: absolute;
            right: 0;
            bottom: 0;
            color: green;
            font-weight: 500;
        }
        .admin_one-object_error {
            position: absolute;
            right: 0;
            bottom: 0;
            color: red;
            font-weight: 500;
        }
        &-left {
            display: flex;
            gap: 15px;
            h3 {
                margin-bottom: 10px;
                font-weight: 700;
                font-size: 18px;
                line-height: 21px;
            }

            span {
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: #858585;
            }
        }

        &-right {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    &_bottom {
        display: flex;
        gap: 20px;

        &-left {
            display: grid;
            gap: 15px;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(4, 1fr);
            width: 180%;
            &_category {
                grid-column-start: 1;
                grid-column-end: 3;
            }
            p {
                display: flex;
                justify-content: space-between;
                padding: 20px;
                background: #f6f8f9;
                border-radius: 5px;
                align-items: center;
            }

            span {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #546edb;
            }

            .admin_one-object_textfield {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 5;
            }

            .admin_one-object_location {
                grid-column-start: 1;
                grid-column-end: 3;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;
            }
        }

        &-right {
            width: 100%;

            .selects {
                cursor: pointer;
                margin-bottom: 20px;
            }

            p {
                max-width: 360px;
                margin-bottom: 20px;
            }

            .imgs-wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                img {
                    width: 160px;
                    border-radius: 10px;
                }
                a:first-child{
                    display: block;
                }
                a{
                    display: none;
                }
            }
        }
    }
}


.admin_one-user {
    &_container {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        a {
            color: inherit;
        }
    }

    &_obj-container {
        padding: 0 10px 20px;
    }

    &_data-left {
        background: #ffffff;
        box-shadow: 0 0 10px rgb(0 0 0 / 10%);
        border-radius: 5px;
        padding: 10px;
        span {
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #858585;
        }
        .admin_data-user {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #4088dd;
        }
    }

    &_back {
        display: flex;
        align-items: start;
        gap: 10px;
        margin-bottom: 10px;
        h3 {
            margin-bottom: 0;
        }
        span {
            padding-left: 20px;
            background-repeat: no-repeat;
            background-position-y: center;
            background-image: url(../assets/images/admin/star.svg);
        }
    }

    &_card {
        width: 100%;
        padding: 18px;
        background: #ffffff;
        border-radius: 10px;

        .admin_register {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #858585;
        }
    }

    &-contacts {
        display: flex;
        align-items: center;
        padding: 20px;
        background-color: #f6f8f9;
        background-repeat: no-repeat;
        background-position: right 16px center;
        background-size: 24px;
    }

    &_top {
        padding: 40px 0;
        justify-content: space-between;
        display: flex;
        gap: 160px;
        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
        }
        &_wallet {
            display: flex;
            gap: 20px;
            &_wrapper {
                padding: 20px 16px 16px 16px;
                border-radius: 8px;
                background: #5075DC;
                color: #fff;
                max-width: 195px;
                &.green_wallet{
                    background: #47BD93;
                }
            }
            h4 {
                font-size: 16px;
                margin-bottom: 2px;
                font-weight: normal;
            }
            p {
                margin-bottom: 10px;
                font-size: 25px;
                font-weight: 700;
            }
            input {
                font-size: 22px;
                height: 38px;
                width: 100%;
                background-color: #f6f8f9;
                border-radius: 16px;
                border: none;
                padding: 2px 8px;
                color: #fff;
            }
            .input__wrapper {
                //display: flex;
                //gap: 10px;
                .input_blue{
                    background: #1A38B6;
                }
                .input_green{
                    background: #189367;
                }
                button {
                    padding: 5px 5px 5px 22px;
                    background: none;
                    border: none;
                    color: #546edb;
                    font-weight: 500;
                    position: relative;
                }
                .button_admins_add_money{
                    width: 128px;
                    height: 38px;
                    border-radius: 46px;
                    background: #FFF;
                    box-shadow: -7.44322px -5.58242px 10.23443px 0px rgba(255, 255, 255, 0.25), 5.58242px 6.51282px 12.09524px 0px #3F59C7;
                    color: #546EDB;
                    font-size: 16px;
                    font-weight: 600;
                    margin-top: 12px;
                    &:before{
                        content: '+';
                        display: flex;
                        font-size: 22px;
                        color: #fff;
                        width: 20px;
                        height: 20px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 100%;
                        background: #546EDB;
                        position: absolute;
                        left: 8px;
                        top: 10px;
                    }
                    &.button_admins_add_point{
                        box-shadow: -7.44322px -5.58242px 10.23443px 0px rgba(255, 255, 255, 0.25), 5.58242px 6.51282px 12.09524px 0px #189367;
                        color: #47BD93;
                        &:before{
                            background: #47BD93;
                        }
                    }
                }
            }
        }
    }

    &_top.no-avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    &_card-top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #c4c4c4;
    }

    &_card-bottom {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(3, 1fr);
        padding-bottom: 10px;
    }

    &_btns {
        display: flex;
        gap: 10px;
    }

    &_posters {
        background: #ffffff;
        border-radius: 10px;
        &_tabs {
            display: flex;
            gap: 15px;
            padding: 10px;
            p {
                cursor: pointer;
                font-weight: 600;
                opacity: 0.3;
            }
            .active {
                opacity: 1;
            }
        }
    }
}

.admin__roles {
    &_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        h3 {
            margin-bottom: 0;
        }
    }
}

.mb_0{
    label{
        p{
            margin-bottom: -10px;
        }
    }
}

.s_green{
    color: #54D848;
    font-weight: 700 !important;
}
.s_red{
    color: #D84848;
    font-weight: 700 !important;
}