$light-blue: #dde2f8
$blue: #546edb
$dark-blue: #4059c1
$checkbox-blue: #4088dd
$purple: #b6bfe7
$purple-light: #f6f7fe
$yellow: rgba(255, 168, 0, 1)
$orange: #ffa800
$red: #d32121
$like-red: #e21111
$grey: #858585
$salad: #6fe1b8
$green: #3fc092
$green-dark: #289a71
$checkbox-green: #07b356
$white: #fff

@mixin h-20
    font-size: 20px
    line-height: 25px

@mixin h-23
    font-weight: 600
    font-size: 23px
    line-height: 28px

@mixin h-25
    font-weight: 600
    font-size: 25px
    line-height: 31px

@mixin h-28
    margin-bottom: 8px
    font-weight: 600
    font-size: 28px
    line-height: 34px

@mixin h-30
    font-weight: 700
    font-size: 30px
    line-height: 37px

@mixin p-18
    font-weight: 600
    font-size: 18px
    line-height: 21px

@mixin p-16-600
    font-weight: 600
    font-size: 16px
    line-height: 19px

@mixin p-16-500
    font-weight: 500
    font-size: 16px
    line-height: 19px

@mixin p-16-400
    font-weight: 400
    font-size: 16px
    line-height: 19px

@mixin p-15-700
    font-weight: 700
    font-size: 15px
    line-height: 19px

@mixin p-15
    font-weight: 300
    font-size: 15px
    line-height: 18px

@mixin p-14-600
    font-weight: 600
    font-size: 14px
    line-height: 17px

@mixin p-14-500
    font-weight: 500
    font-size: 14px
    line-height: 17px

@mixin p-14-400
    font-weight: 500
    font-size: 14px
    line-height: 17px

@mixin p-12
    font-weight: 400
    font-size: 12px
    line-height: 14px

@mixin p-11
    font-weight: 400
    font-size: 11px
    line-height: 13px

@mixin p-10
    font-weight: 400
    font-size: 10px
    line-height: 12px

@mixin p-7
    font-weight: 500
    font-size: 7px
    line-height: 9px
