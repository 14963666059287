.top
    margin-bottom: 30px
    display: flex
    flex-wrap: wrap
    justify-content: space-between
.left
    display: flex
    align-items: center
    gap: 10px
    svg
        cursor: pointer
    h3
        margin-bottom: 0
.right
    position: relative
.success
    position: absolute
.titles
    display: flex
    margin-bottom: 10px
    p
        width: 100%
.rates
    display: flex
    gap: 10px
    align-items: center
    border-bottom: 1px solid #dde2f8
    border-radius: 10px
    &:not(:last-child)
        margin-bottom: 15px
    p
        width: 100%
        padding: 15px
        border: none
.edit
    height: 50px
    cursor: pointer
    display: flex
    align-items: center
    &:hover svg path
        fill: #546edb

@media (max-width: 480px)
    .right
        margin-top: 10px